import React from "react";

// Customizable Area Start

import {
    Typography, Box, TextField,
    Card, Button,
    Tabs, Tab,
    CardContent, Checkbox,
    Divider,
    Dialog,    
    DialogContent,
    styled,
    DialogTitle,
    Grid,
    FormControl,
    InputAdornment,
} from "@material-ui/core";
import {
    withStyles, Theme, ThemeProvider
} from "@material-ui/core/styles";
import { Edit,} from "@material-ui/icons";
import StarRateIcon from '@material-ui/icons/StarRate';
import CloseIcon from '@material-ui/icons/Close';
import { Formik, Form} from "formik";
import { primaryColor } from "../../../components/src/theme";
import Breadcrumbs from "../../../components/src/Breadcrumbs";
import {
    signUptheme, verifyEmailModal
} from "../../email-account-registration/src/EmailAccountRegistration.web";
import {
    dummyText,
    formatNullishValue
} from "../../../components/src/utils";
import ResponseStatusModal from "../../../components/src/ResponseStatusModal";
import i18next from 'i18next';
import {creaditCard} from "./assets";
const configJSON = require("./config");
const ModifiedData = (
    values: Record<string, any>,
    initialValues: Record<string, any>
) => {
    let modifiedValuesData: any = {};

    if (values) {
        Object.keys(values).forEach((key) => {
            const newValue = values[key];
            const oldValue = initialValues[key];
            if (key === 'mobile_number' || key === 'selectedCountry') {
                modifiedValuesData[key] = (newValue !== undefined) ? newValue : oldValue;
            } else if (newValue !== initialValues[key]) {
                modifiedValuesData[key] = newValue;
            }
        });
    }

    return modifiedValuesData;
};

const tabList = [
    { label: 'Personal Details', value: '0' },
    { label: 'Entity Details', value: 'Entity Details' },
    { label: 'Business Details', value: 'Business Details' },
]

export const ButtonStyle = withStyles({
    root: {
        textTransform: 'none',
        backgroundColor: primaryColor,
        color: '#ffffff',
        minWidth: '160px',
        width: '100%',
        height: '56px',
        maxWidth: '228px',
        flexShrink: 0,
        textAlign: 'center',
        borderRadius: '49px',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '150%',
        fontFamily: [
            'Inter',
            'sans-serif',
        ].join(','),
        '&:hover': {
            backgroundColor: primaryColor,
        },
        '&.Mui-disabled': {
            color: '#FFF',
            opacity: 0.6
        }
    },
})(Button);

// Customizable Area End

import Settings5Controller, {
        Props
} from "./Settings5Controller";
import PersonalDetailsForm from "../../../components/src/PersonalDetails";
import TravelBuddyEntityDetails from "../../../components/src/TravelBuddySteps/TravelBuddyEntityDetails.web"
import EntityDetails from "../../../components/src/EntityDetails";
import BusinessDetails from "../../../components/src/BusinessDetails";
import { editPen } from "./assets";

export default class MyProfile extends Settings5Controller {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
     formatDate(createdAt :any) {
        if (createdAt) {
            const formattedDate = new Date(createdAt).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
            return formattedDate;
        } else {
            return null;
        }
    }

     conditionShort = (conditon: any, trueStatetment: any, falseStatetment: any) => {
        return conditon ? trueStatetment : falseStatetment
    }

    
   
    
 
     card = (classes:any , formattedDate : any , formattedrenewalDate:any , subscriptionPrice :any ,currency:string)=>(
        
        <React.Fragment>
            <CardContent style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} data-testid="subscription-container">
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography style={{ fontWeight: 'bold', color: 'black', fontSize: '20px' }} gutterBottom data-testid="subscription-heading">
                    {i18next.t('travelAgency.mySubscription.mySubscription')}
                    </Typography>
                    <Button onClick={() => this.subscriptionPlan()} data-testid = "subscriptionPlanBtn" size="small"className={this.getPlanTypeClass()} >
                        {this.getPlanType()}
                    </Button>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gridGap: '16px', marginTop: '16px' }}>
                    <Box className={classes.cardBoxes}>
                        <Typography className={classes.cardkeys} variant="body2" component="div">
                        {i18next.t('travelAgency.mySubscription.startsOn')}
                        </Typography>
                        <Typography variant="body2" component="div">
                            {formattedDate}
                        </Typography>
                    </Box>
                    <Box className={classes.cardBoxes}>
                        <Typography className={classes.cardkeys} variant="body2" component="div">
                        {i18next.t('travelAgency.mySubscription.renewalDate')}
                        </Typography>
                        <Typography variant="body2" component="div">
                        {formattedrenewalDate}
                        </Typography>
                    </Box>
                    <Box className={classes.cardBoxes}>
                        <Typography className={classes.cardkeys} variant="body2" component="div">
                        {i18next.t('travelAgency.mySubscription.amount')}
                        </Typography>
                        <Typography variant="body2" component="div">
                            {subscriptionPrice} {currency}
                        </Typography>
                    </Box>
                </Box>

            </CardContent>

           { !this.state.ischecked &&
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button data-testId = "perfectPlanBtn" onClick={this.perfectPlanModalOpen} variant="contained" style={{ background: '#FE7F2D', color: '#fff', borderRadius: '30px',textTransform:"capitalize",  fontFamily: "inter", fontWeight:500, width:"140px", fontSize:"14px",height:"45px" }}>{i18next.t('travelAgency.mySubscription.upgradePlan')}</Button>
                <Button size="small" style={{ color: '#FE7F2D',textDecorationThickness: "2px",textTransform:"capitalize", fontFamily: "inter",fontSize:"14px", fontWeight:700,width:"150px",textDecoration: "underline" }}>
                {i18next.t('travelAgency.mySubscription.historicalInvoices')}
                </Button>
            </Box>}
            
            <Divider style={{ borderBottom: '1px dashed grey', marginTop: '20px' }} />
            <Box sx={{ padding: '15px' }}>
                <Typography variant="h6">{i18next.t('travelAgency.mySubscription.autoRenewal')}</Typography>
                <Typography variant="body2" style={{ marginTop: '15px' }}> {i18next.t('travelAgency.mySubscription.autoRenewalMsg')}</Typography>
                <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginTop: '10px' }}>
                    <Checkbox style={{color:'FE7F2D'}}  checked={this.state?.ischecked}
                    data-testid = "autorenewalcheckbox" onChange={this.updateAutoRenewalApiRequest} />
                    {i18next.t('travelAgency.mySubscription.checkBoxMsg')}
                </Box>

            </Box>
            {this.state.ischecked &&
                <>
                    <Card className={classes.carddetails} data-testid = "saved-card-container">
                        <Typography data-testid = "saved-card-heading">Saved Card</Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography>***** **** **** {(this.context?.currentUser?.card_details?.[0]?.card_number)?.slice(-4)}</Typography>
                            <Edit style={{ fontSize: '20px', cursor:'pointer'}} onClick={this.handleEditClick} data-testid = "saved-card-editbtn"/>

                        </Box>
                    </Card>
                    <Divider style={{ borderBottom: '1px dashed grey', marginTop: '15px' }} />
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                        <Button variant="contained" style={{ background: '#FE7F2D', color: '#fff', borderRadius: '30px', textTransform:"capitalize",fontFamily: "inter", fontWeight:500, width:"140px", fontSize:"14px",height:"45px" }} data-testId = "renewManuallyTestId" onClick={this.renewManually}>Renew manually</Button>
                        <Button size="small" style={{ textTransform:"capitalize",color: '#FE7F2D',fontFamily: "inter", fontWeight:700, width:"150px", fontSize:"14px", textDecoration: "underline",textDecorationThickness: "2px", }}>
                        {i18next.t('travelAgency.mySubscription.historicalInvoices')}
                        </Button>
                    </Box>
                </>
            }
        </React.Fragment>
      );    

    perfectPlanDialog = () => {
        return (
            <DialogContainer
            data-testId = "perfectModalClose"
                open={this.state.perfectPlanModal}
                onClose={this.perfectPlanModalClose}
                aria-labelledby="responsive-dialog-title"
            >
                <Box style={{ width: "100%", display: "flex", justifyContent: "flex-end",padding: "10px 10px" }}>
                    <CloseIcon style={{cursor:"pointer"}} onClick= {this.perfectPlanModalClose}/>
                </Box>
                <DialogTitle id="responsive-dialog-title" className="perfectPlanTitle">
                Pick your perfect plan!
                </DialogTitle>
                <DialogContent>
                    <Typography className="perfectPlanSubTitle">{configJSON.planAcceptReasion}</Typography>
                    <Grid container>
                        <div  className= "subscriptionPlanContainer">
                            {this.state.pickPlanData.map((item)=>{
                                return(
                                    <Box className="packPlanContainer" >
                                <Box>
                                    {item.attributes.discount_percent != 0 &&
                                    <Box className="offerBag">
                                    <Typography variant="h6" style={{ fontWeight: 500, fontSize: "12px", color: "white" }}>
                                        {item.attributes.discount_percent}%
                                    </Typography>
                                    <Typography variant="caption" style={{ fontSize: '12px', fontWeight: 500, color: "white" }}>
                                        OFF
                                    </Typography>
                                </Box>}
                                    <Typography className="annualPack">{this.subscriptionPlanHandle(item.attributes.monthly_amount,item.attributes.yearly_amount)? "ANNUAL PACK":"MONTHLY PACK"}</Typography>
                                    <Divider style={{ width: "60%", margin: "20px 0px" }} />
                                    <Typography className="packageAmount grayColor"><span className="blackColor priceRate" >{this.subscriptionPlanAmount(item.attributes.monthly_amount,item.attributes.yearly_amount)}</span> {item.attributes.currency} / {this.subscriptionPlanHandle(item.attributes.monthly_amount,item.attributes.yearly_amount)? "year":"month"}</Typography>
                                    {this.subscriptionPlanHandle(item.attributes.monthly_amount,item.attributes.yearly_amount) && 
                                    <Typography className="grayColor packageAmount">
                                        ( <span className="blackColor priceRate14pxFontSize">83</span> SAR / month )</Typography>}
                                    <Typography className="perfectPlanSubTitle offerText">{item.attributes.description}</Typography>
                                    <Button className="getStartedButton" data-testId = "getStartedBtnTestId" onClick = {this.getStartedButton}>Get Started</Button>
                                </Box>
                            </Box>
                                )
                            })}
                            
                        </div>
                    </Grid>
                </DialogContent>
            </DialogContainer>
        )
    };

    renewalPayment = () => {
        return (
            <RenewalDialogContainer
                data-testId= "renewalOpenTestId"
                open={this.state.linkCardOpen}
                onClose={this.renewManuallyCloseModal}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title" className="renewallinkTitle">
                    Link your card
                </DialogTitle>
                <DialogContent>
                    <Typography className="renewalPlanTitle">Link your card to enable the auto renewal option.</Typography>
                    <Box className="renewalPlanContainer">
                        <Box>
                            <Box className="planContainer" >
                                <Box className="planTypeBox">
                                    <StarRateIcon className="starIcon" />
                                </Box>
                                <Typography className="planTextType" >Monthly Plan</Typography>
                            </Box>
                            <Typography className="planChange" data-testId = "changePlanDataTestId" onClick= {this.handleChangePlan}>Change plan</Typography>
                        </Box>
                        <Box>
                            <Typography className="planAmountBox">149.00<span className="currancyType">SAR</span> </Typography>
                            <Typography className="priceTypeText">Price per month</Typography>
                        </Box>
                    </Box>
                    {this.state.checkCardInformation && <Box className="cardErrorBox">
                        <Typography className="errorText">{configJSON.errorText}</Typography>
                    </Box>}
                    <Grid container>
                        <Box>
                            <FormControl fullWidth>
                                <Typography
                                    className="lableNameText"
                                >
                                    Card number
                                </Typography>
                                <TextField
                                    name="cardNumber"
                                    placeholder="cardNumber"
                                    fullWidth
                                    value={this.state.cardNumber}
                                    data-testId = "cardnameTestId"
                                    onChange={this.changeCardNumber}
                                    InputProps={{
                                        disableUnderline: true,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <img alt="creditcard" src={creaditCard} height='100%' width='100%' />
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="standard"
                                />
                            </FormControl>
                            <FormControl fullWidth>
                                <Typography
                                    className="lableNameText"
                                >
                                    Card holder name
                                </Typography>
                                <TextField
                                    name="cardHolderName"
                                    placeholder="cardHolderName"
                                    fullWidth
                                    data-testId = "cardHolderTestId"
                                    value={this.state.cardHolderName}
                                    onChange={this.changeCardHolderName}
                                    InputProps={{
                                        disableUnderline: true,
                                    }}
                                    variant="standard"
                                />
                            </FormControl>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <FormControl fullWidth>
                                        <Typography
                                            className="lableNameText"
                                        >
                                            Expiration
                                        </Typography>
                                        <TextField
                                            name="expiration"
                                            placeholder="MM / YY"
                                            fullWidth
                                            data-testId = "expirationTestId"
                                            value={this.state.expiration}
                                            onChange={this.editExpirationChange}
                                            InputProps={{
                                                disableUnderline: true,
                                            }}
                                            variant="standard"
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl fullWidth>
                                        <Typography
                                            className="lableNameText"
                                        >
                                            Security code
                                        </Typography>
                                        <TextField
                                            name="securityCode"
                                            placeholder="securityCode"
                                            fullWidth
                                            data-testId = "securityCodeTestId"
                                            value={this.state.securityCode}
                                            onChange={this.editSecurityChange}
                                            InputProps={{
                                                disableUnderline: true,
                                            }}
                                            variant="standard"
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Box className="buttonContainer">
                                <Button className="saveBtn" data-testId = "checkCardInfoTestId" onClick={this.checkCardInformation}>
                                    Save
                                </Button>
                                <Button
                                    onClick={this.renewManuallyCloseModal}
                                    className="cancleBtn"
                                >
                                    Cancel
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                </DialogContent>
            </RenewalDialogContainer>
        )
    };

    // Customizable Area End

    render() {
        // Customizable Area Start
     const formattedDate = this.formatDate(this.state?.isSubscription?.created_at)
     const formattedrenewalDate = this.formatDate(this.state?.isSubscription?.renewal_date)
     const subscriptionPrice = this.state?.isSubscription?.subscription_price
     const currency = this.state?.isSubscription?.currency
             const { classes } = this.props
        const { isEditProfile, isEmailVerified,
            countdown, data: {
                first_name = '',
                last_name = '',
                email = '',
                Facebook = "",
                Instagram = "",
                Website = "",
                phone_number: {
                    country_code = '',
                    number = ''
                } = {},
                profile_image = null,
            } = {},
            responseModal, responseMsg, isSuccessIcon
        } = this.state


        const cuurentuserPersonal = this.context?.currentUser
        const currentuserentity = cuurentuserPersonal?.entity_detail?.data?.attributes
        const currentuserBusiness = cuurentuserPersonal?.business_detail?.data?.attributes
        const initialPersonalDetails = {
            fname: cuurentuserPersonal?.first_name,
            lname: cuurentuserPersonal?.last_name,
            email: cuurentuserPersonal?.email,
            phoneNumber: cuurentuserPersonal?.phone_number?.number ,
            password: dummyText,
            facebook_link: cuurentuserPersonal?.facebook_link,
            instagram_link: cuurentuserPersonal?.instagram_link,
            website_link: cuurentuserPersonal?.website_link,
            service_provider_type: "buddy",
            onlyPhone: cuurentuserPersonal?.phone_number?.number,
            country_code: cuurentuserPersonal?.phone_number?.country_code,
        }
        const initialEntityDetailsAgency = {
            agency_logo: cuurentuserPersonal?.profile_image,
            agency_display_name: currentuserentity?.display_name,
            about_me: currentuserentity?.about_me,
            company_legal_name: currentuserentity?.company_legal_name,
            id_number: currentuserentity?.id_number,
            commercialPdf: currentuserentity?.commerical_registration_document,
            permitPdf: currentuserentity?.permit_document,
            certificatePdf: currentuserentity?.certificate_file,
            vat_number: currentuserentity?.vat_number,
        }

        const initialEntityDetailsBuddy = {
            buddy_profile_picture: cuurentuserPersonal?.profile_image,
            buddy_name: currentuserentity?.display_name,
            about_me: currentuserentity?.about_me,
            id_number: currentuserentity?.id_number,
            commercialPdf: currentuserentity?.commerical_registration_document,
            tourismPdf: currentuserentity?.certificate_file,
            permitPdf: currentuserentity?.freelance_permit,
            vat_number: currentuserentity?.vat_number,
        }

        const initialBusinessDetails = {
            bankName: currentuserBusiness?.bank_name,
            accountName: currentuserBusiness?.account_name,
            accountNumber: currentuserBusiness?.account_number,
            iban: currentuserBusiness?.iban,
            streetName: currentuserBusiness?.street_name,
            country: cuurentuserPersonal?.country?.data?.attributes?.name,
            city: cuurentuserPersonal?.city?.data?.attributes?.name,
            zipCode: currentuserBusiness?.zipcode,
            agreeTermsAndCondition: true
        }

        const countTime = `${Math.floor(countdown / 60)}:${(countdown % 60).toString().padStart(2, '0')}`;

        const detailsBlock = (list: Record<string, any>, className: string) => {
            return <Box display={'flex'} gridRowGap={'32px'} flexDirection={'column'} className={className} width={'100%'}>
                {Object.entries(list).map(([label, value], index) => {
                    const isBold = label === 'Bank Details' || label === 'National Address' || label === 'Legal Document';
                    let button = null;
                    const documentLabels = ['Commercial Registration Document', 'Certificate File', 'Permit Document', 'Commercial Registartion', 'Tourism Certificate', 'Freelance Permit'];
                    const linkLabels = ['Facebook', 'Instagram', 'WhatsApp'];
                    const isLink = linkLabels.includes(label);
                    const isDocuments = documentLabels.includes(label);
                    
                if (documentLabels.includes(label)) {
                   button = <Button onClick={() => this.handleViewFile(value, isDocuments)} variant="text" className={classes.editdetailstyles}>{i18next.t('travelAgency.myProfile.viewFile')}</Button>;
                   }
                    return (

                        <Box key={index} sx={{ display: 'flex' }}>
                            <Box sx={{ width: '35%' }}>
                                <Typography className={`${classes.lightLabelTextstyle} ${this.conditionShort(isBold, classes.boldLabel, '')}`}>
                                    {label}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography className={classes.valueTextstyle}>
                                    <span onClick={() => this.handleViewFile(value, isLink)} className={`${classes.valueTextstylespan} ${isLink ? classes.link : ''}`}>
                                        {this.conditionShort(typeof value === 'object', JSON.stringify(value), value)}
                                    </span>
                                    {button}
                                </Typography>
                            </Box>
                        </Box>


                    );
                })}

            </Box>;
        };
        
        const userBlockDetail = () => {
            const user = this?.context?.currentUser
            let detailsToDisplay: any;
            const mobile_number = user?.phone_number?.country_code+user?.phone_number?.number;
            switch (this.state.tab) {

                case '0':
                    detailsToDisplay = {
                        [i18next.t('travelAgency.myProfile.firstName')]: user?.first_name,  
                        [i18next.t('travelAgency.myProfile.lastName')]: user?.last_name,
                        [i18next.t('travelAgency.myProfile.emailAddress')]: user?.email,
                        [i18next.t('travelAgency.myProfile.mobileNumber')]: formatNullishValue(mobile_number),
                        [i18next.t('travelAgency.myProfile.facebook')]: formatNullishValue(user?.facebook_link),
                        [i18next.t('travelAgency.myProfile.instagram')]: formatNullishValue(user?.instagram_link),
                        [i18next.t('travelAgency.myProfile.WhatsApp')]: formatNullishValue(user?.website_link)
                    };
                    break;

                case 'Entity Details':

                    /* istanbul ignore next */
                    if (user?.account_type === 'buddy') {
                        detailsToDisplay = {
                            [i18next.t('travelAgency.myProfile.buddyDisplayName')]: user?.entity_detail?.data?.attributes?.display_name,
                            [i18next.t('travelAgency.myProfile.aboutMe')]: formatNullishValue(user?.entity_detail?.data?.attributes?.about_me),
                            [i18next.t('travelAgency.myProfile.idNumber')]: user?.entity_detail?.data?.attributes?.id_number,
                            [i18next.t('travelAgency.myProfile.legalDocument')]: '',
                            [i18next.t('travelAgency.myProfile.commercialRegistration')]: user?.entity_detail?.data?.attributes?.commerical_registration_document,
                            [i18next.t('travelAgency.myProfile.tourismCertificate')]: user?.entity_detail?.data?.attributes?.tourism_certificate,
                            [i18next.t('travelAgency.myProfile.freelancePermit')]: user?.entity_detail?.data?.attributes?.freelance_permit,
                            [i18next.t('travelAgency.myProfile.vatNumber')]: formatNullishValue(user?.entity_detail?.data?.attributes?.vat_number)
                        };
                    } else {
                        /* istanbul ignore next */
                        detailsToDisplay = {
                            [i18next.t('travelAgency.myProfile.agencyDisplayName')]: user?.entity_detail?.data?.attributes?.display_name,
                            [i18next.t('travelAgency.myProfile.aboutMe')]: formatNullishValue(user?.entity_detail?.data?.attributes?.about_me),
                            [i18next.t('travelAgency.myProfile.companyLegalName')]: user?.entity_detail?.data?.attributes?.company_legal_name,
                            [i18next.t('travelAgency.myProfile.idNumber')]: user?.entity_detail?.data?.attributes?.id_number,
                            [i18next.t('travelAgency.myProfile.commercialRegistrationDocument')]: user?.entity_detail?.data?.attributes?.commerical_registration_document,
                            [i18next.t('travelAgency.myProfile.permitDocument')]: user?.entity_detail?.data?.attributes?.permit_document,
                            [i18next.t('travelAgency.myProfile.certificateFile')]: user?.entity_detail?.data?.attributes?.certificate_file,
                            [i18next.t('travelAgency.myProfile.vATNumber')]: formatNullishValue(user?.entity_detail?.data?.attributes?.vat_number)
                        };
                    }
                    break;
                case 'Business Details':
                    detailsToDisplay = {
                        [i18next.t('travelAgency.myProfile.bankDetails')]: '',
                        [i18next.t('travelAgency.myProfile.bankName')]: user?.business_detail?.data?.attributes?.bank_name,
                        [i18next.t('travelAgency.myProfile.accountName')]: user?.business_detail?.data?.attributes?.account_name,
                        [i18next.t('travelAgency.myProfile.accoutnNumber')]: user?.business_detail?.data?.attributes?.account_number,
                        [i18next.t('travelAgency.myProfile.IBAN')]: user?.business_detail?.data?.attributes?.iban,
                        [i18next.t('travelAgency.myProfile.nationaladdress')]: '',
                        [i18next.t('travelAgency.myProfile.country')]: user?.country?.data?.attributes?.name,
                        [i18next.t('travelAgency.myProfile.city')]: user?.city?.data?.attributes?.name,
                        [i18next.t('travelAgency.myProfile.streetName')]: user?.business_detail?.data?.attributes?.street_name,
                        [i18next.t('travelAgency.myProfile.zipCode')]: user?.business_detail.data?.attributes?.zipcode
                    };
                    break;
            }
            const agencyLogo = this.context?.currentUser?.entity_detail?.data?.attributes?.agency_logo
            const buddyImg = this.context?.currentUser;
            return <Box margin={'0 71px 0 0'} width={'100%'}
                className={classes.userDetails}>
                {this.state.tab === 'Entity Details' && (
                    <Box className={classes.logoContainer} sx={{ borderRight: '1px dashed grey', padding: '0px 35px' }}>
                        <Typography style={{ whiteSpace: 'nowrap' }}>{user?.account_type} Logo</Typography>
                        <Box sx={{ border: '1px solid grey', borderRadius: '50%', overflow: 'hidden', width: '80px', height: '80px' }}>
                            <img src={buddyImg?.profile_image} alt="Company logo" className={classes.agencyImg} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                        </Box>
                    </Box>
                )}
                <Box width={'100%'}>
                    {detailsBlock(detailsToDisplay, classes.detailsLeft)}
                </Box>
            </Box>
        }


        const editRenewal = (classes: any) => {

            const cardDetails = this.context?.currentUser?.card_details[0]
            const initialCardValues = {
                card_detail: {
                    card_number: cardDetails?.card_number,
                    cardholder_name: cardDetails?.cardholder_name,
                    expiration_date: (cardDetails?.expiration_date),
                    cvv: (cardDetails?.cvv)
                }
            };

            return (<Dialog open={true} onClose={() => { }} maxWidth="xs" >
                <Box style={{ padding: '20px', borderRadius: '30px' }}>
                    <Box
                        display="flex"
                        flexDirection='column'
                        justifyContent="flex-start"
                        p={2}
                        style={{ padding: '20px' }}
                        data-testid="edit-model-container"
                    >
                        <Typography data-testid="edit-model-heading" variant="body1" style={{ fontSize: '20px', fontWeight: 'bold' }}>Link your card</Typography>
                        <Typography variant="body2">Link your card to update auto renewal</Typography>
                    </Box>
                    <Box>
                        <DialogContent>



                            <Formik
                                initialValues={initialCardValues}
                                onSubmit={this.updateCardDetailsApiRequest}
                            >
                                {({ values, setFieldValue, errors, touched, setTouched }) => (
                                    <Form>
                                        <Box style={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
                                            <Box>
                                                <Typography>Card number</Typography>
                                                <TextField
                                                    autoFocus
                                                    id="cardnumber"
                                                    name="card_number"
                                                    placeholder="Enter card number"
                                                    type="text"
                                                    fullWidth
                                                    variant="outlined"
                                                    onChange={(e) => {
                                                        setFieldValue("card_detail['card_number']", e.target.value);
                                                    }}
                                                    value={values.card_detail.card_number}
                                                />
                                            </Box>
                                            <Box>
                                                <Typography>Card holder name</Typography>
                                                <TextField
                                                    autoFocus
                                                    id="cardHolderName"
                                                    name="cardholder_name"
                                                    placeholder="Enter card holder name"
                                                    type="text"
                                                    fullWidth
                                                    variant="outlined"
                                                    required
                                                    onChange={(e) => {
                                                        setFieldValue("card_detail.cardholder_name", e.target.value);
                                                    }}
                                                    value={values?.card_detail?.cardholder_name}
                                                />
                                            </Box>
                                            <Box sx={{ display: 'flex', gridGap: '20px' }}>
                                                <Box>
                                                    <Typography>Expiration</Typography>
                                                    <TextField
                                                        autoFocus
                                                        id="expiration"
                                                        name="expiration_date"
                                                        placeholder="mm/yy"
                                                        type="text"
                                                        fullWidth
                                                        variant="outlined"
                                                        required
                                                        onChange={(e) => {
                                                            setFieldValue("card_detail.expiration_date", e.target.value);
                                                        }}
                                                        value={values?.card_detail?.expiration_date}
                                                    />
                                                </Box>
                                                <Box>
                                                    <Typography>Security code</Typography>
                                                    <TextField
                                                        autoFocus
                                                        id="cvv"
                                                        name="cvv"
                                                        placeholder="Enter security code"
                                                        type="text"
                                                        fullWidth
                                                        variant="outlined"
                                                        required
                                                        onChange={(e) => {
                                                            setFieldValue("card_detail.cvv", e.target.value);
                                                        }}
                                                        value={values?.card_detail?.cvv}
                                                    />
                                                </Box>
                                            </Box>
                                            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <Button variant="outlined" data-testid="carddetailssave" style={{ padding: '10px 60px', borderRadius: '20px', color: '#fff', background: '#FE7F2D' }} type="submit" >Save</Button>
                                                <Button variant="outlined" data-testid="cancleBtn" style={{ padding: '10px 55px', borderRadius: '20px', color: '#FE7F2D' }} onClick={this.handleClose}>Cancel</Button>
                                            </Box>
                                        </Box>
                                    </Form>
                                )}
                            </Formik>

                        </DialogContent>
                    </Box>
                </Box>
            </Dialog>)

        }


       
        const renderForm = (tab: any, email: any, initialValue: any) => {
            switch (tab) {
                case '0':
                    return (
                        <PersonalDetailsForm
                            sendEmailOrSmsOtpRequest={()=>{}}
                            handleValueChange={this.handleValueChange}
                            email={email}
                            mobile={this.state.mobile}
                            countryData={this.state?.countryList}
                            initialState={initialPersonalDetails}
                            submitPersonalDetails={() => { }}
                            traveleAgencyEditRequest={this.traveleAgencyEditRequest}
                            errorMsg={this.state.errorMsg}
                            isEmailVarified={true}
                            isPhoneVarified={true}
                            phoneErrorMsg={""}
                            emailErrorMsg={""}
                            isEditClicked={isEditProfile}
                            handleBack={this.handleToggleEditBtn}

                        />
                    );
                case 'Entity Details':
                    if (this.context?.currentUser?.account_type === 'agency') {
                        return <EntityDetails
                            agency_logo=""
                            initialState={initialEntityDetailsAgency}
                            submitEntityDetails={() => { }}
                            traveleAgencyEditRequest={this.traveleAgencyEditRequest}
                            handleBack={this.handleClose}
                            isEditClicked={isEditProfile}
                        />;
                    } else {
                        return <TravelBuddyEntityDetails
                            initialState={initialEntityDetailsBuddy}
                            submitEntityDetails={() => { }}
                            traveleAgencyEditRequest={this.traveleAgencyEditRequest}
                            handleBack={this.handleToggleEditBtn}
                            isEditClicked={isEditProfile}
                        />;
                    }
                case 'Business Details':
                    return <BusinessDetails
                        countries={this.state.countryList}
                        cities={this.state.cityList}
                        getCityListRequest={this.getCityListRequest}
                        initialState={initialBusinessDetails}
                        traveleAgencyEditRequest={this.traveleAgencyEditRequest}
                        submitBusinessDetails={() => { }}
                        handleBack={this.handleToggleEditBtn}
                        errorMsg={""}
                        isEditClicked={isEditProfile} />;
                
            }
        }
        
        // Customizable Area End
        return (
            // Customizable Area Start
            <ThemeProvider theme={signUptheme}>
                <Box className={classes.container}>
                    <ResponseStatusModal
                        open={responseModal}
                        message={responseMsg}
                        isSuccess={isSuccessIcon}
                        onClose={this.handleCloseResponseModal}
                    />
                    <Breadcrumbs list={[
                        {
                            title: i18next.t('travelAgency.home.name'),
                            link: '/Dashboard'
                        },
                        {
                            title: i18next.t('travelAgency.myProfile.myProfile')
                        }
                    ]} />
                    <Typography className={classes.dividerStyle}>
                        {i18next.t('travelAgency.myProfile.myProfile')}
                    </Typography>
                    <Box className={`${this.conditionShort(isEditProfile, classes.editProfileInfo, classes.userProfileInfo)}`}>
                        <Card className={classes.cardWrapper}>
                            <Box display={'flex'} justifyContent={'space-between'} alignItems={'flex-start'} marginBottom={ this.conditionShort(isEditProfile, '48px', '32px')}>
                                <Box display={'flex'} justifyContent={'space-between'} alignItems={'flex-start'} gridGap={'28px'}>
                                    <Tabs
                                        id="tabs"
                                        value={this.state.tab}
                                        data-testid="tabs"
                                        classes={{
                                            flexContainer: classes.flexContainer,
                                            root: classes.rootTabs
                                        }}
                                        TabIndicatorProps={{
                                            style: { backgroundColor: '#FE7F2D', }
                                        }}
                                        onChange={(event: any, value: string) =>
                                            this.handleChange(value)
                                        }
                                    >
                                        {tabList.map(({ label, value }, index) => {
                                             let profileTitle = '';  
                                             if (label === 'Personal Details') {
                                                profileTitle = i18next.t('travelAgency.myProfile.personalDetails');
                                             } else if (label === 'Entity Details') {
                                                profileTitle = i18next.t('travelAgency.myProfile.entityDetails');
                                             } else if (label === 'Business Details') {
                                                profileTitle = i18next.t('travelAgency.myProfile.businessDetails');
                                             } 

                                            return <Tab
                                                key={index}
                                                classes={{
                                                    selected: classes.selectedTab,
                                                    wrapper: classes.tabItem,
                                                    textColorInherit: classes.textColor,
                                                    root: classes.rootTab,
                                                }}
                                                label={profileTitle} value={value}
                                            />
                                        })}
                                    </Tabs>

                                </Box>
                                <Box flex={4} display={'flex'} justifyContent={'flex-end'}>
                                    {!isEditProfile &&
                                        <Typography className={classes.editdetailstyle} data-testid='editDetailsBtn' onClick={this.handleToggleEditBtn}>
                                            <img src={editPen} alt='Edit' className={classes.userImage} /> {i18next.t('travelAgency.myProfile.editDetails')}
                                        </Typography>
                                    }
                                </Box>
                            </Box>
                            <Box display={'flex'} width={'100%'}>
                                
                            
                                {
                                    this.conditionShort(isEditProfile ,renderForm(this.state.tab, email, initialPersonalDetails),userBlockDetail() )
                                }
                                
                            </Box>

                        </Card>
                        {this.state?.isSubscription && !isEditProfile &&
                            <Box>
                                <Card className={classes.userProfileCard}>{this.card(classes, formattedDate, formattedrenewalDate, subscriptionPrice,currency)}</Card>
                            </Box>
                        }
                    </Box>
                    {verifyEmailModal({ formattedTime: countTime, instance: this })}
                    <ResponseStatusModal
                        open={this.state.openStatusModal}
                        isSuccess={this.state.isSuccessIcon}
                        message={this.state.modalMsg}
                        onClose={this.closeStatusModal}
                    />
                </Box >
                {this.state?.isEditClickd && editRenewal(classes)}
                {this.perfectPlanDialog()}
                {this.renewalPayment()}
            </ThemeProvider >
            // Customizable Area End
        );
    }
}

// Customizable Area Start

export const ProfileStyles = (theme: Theme) => ({
    container: {
        margin: '0px 0 20px',
    },
    rootTabs: {
        minHeight: '32px',
        height: '100%'
    },
    rootTab: {
        display: 'flex',
        padding: '4px 4px',
        flexDirection: 'column' as const,
        justifyContent: 'center',
        alignItems: 'flex-start',
        gap: '4px',
        minWidth: 'auto',
        minHeight: 'auto'
    },
    flexContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        gap: '24px',
    },
    selectedTab: {
        '& span': {
            color: '#212426',
            fontFeatureSettings: "'cv11' on, 'cv01' on, 'ss01' on",
            fontFamily: 'Inter',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '20px',
        }
    },
    agencyImg: {
        objectFit: 'cover',
        borderRadius: '50px'
    },
    tabItem: {
        color: 'rgba(0, 0, 0, 0.40)',
        fontFeatureSettings: "'cv11' on, 'cv01' on, 'ss01' on",
        fontFamily: 'Inter',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '20px',
        textTransform: 'none' as const
    },
    carddetails: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        justifyContent: 'flex-start',
        padding: '20px 30px',
        borderRadius: '30px',
        background: '#FFF2D7'
    },
    cardWrapper: {
        padding: '48px',
        borderRadius: '16px',
        border: '0.5px solid rgba(33, 36, 38, 0.20)',
        background: '#FFF',
        boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.04)',
    },
    userProfileInfo: {
        width: '75vw',
        display: 'flex',
        flexDirection: 'row',
        gap: '20px'
    },
    userProfileCard: {
        width: '20vw',
        borderRadius: '16px',
        background: '#FFDBAC',
        padding: '15px'

    },
    userProfilesecondCard:{
        marginTop : '16px' ,
     padding : '30px',
     borderRadius : '16px'
    },
   
    cardkeys : {
      width:'100px'
    },
    editProfileInfo: {
        maxWidth: '900px',
    },
    userDetails: {
        display: 'flex',
        gap: '20px'
    },
    heading: {
        // color: '#212426',
        // fontSize: '20px',
        fontWeight: 500,
        // lineHeight: '100%',
    },
    editdetailstyle: {
        color: primaryColor,
        fontWeight: 500,
        display: 'flex',
        gap: '8px',
        alignItems: 'center',
        cursor: 'pointer'
    },
    cardbtns: {
        padding: '10px 60px',
        background: '#FE7F2D',
        color: '#fff',
        borderRadius: '20px'
    },
    textfields: {
        height: '44px'
    },
    editdetailstyles: {
        color: '#FE7F2D',
        textTransform: 'initial',
        fontWeight: '600',
        marginLeft: '30px',
        whiteSpace: 'nowrap'

    },
    logoContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '30px',
        padding: '0px 30px 40px 0px'
    },
    lightLabelTextstyle: {
        fontWeight: 500,
        whiteSpace: 'noWrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '340px',
        minWidth: 'fit-content'


    },
    cardBoxes:{
      display:'flex',
      gap:'20px'
    },

    boldLabel: {
        fontWeight: 'bold'
    },

    valueTextstyle: {
        color: 'rgba(33, 36, 38, 0.90)',
        fontWeight: 500,
        width: '320px',
        height: '24px',
        display: 'flex',
        marginLeft: '40px'
    },
    valueTextstylespan: {

        color: 'rgba(33, 36, 38, 0.90)',
        width: '240px',
        fontWeight: 500,
        height: '24px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap' as const,
        display: 'block'

    },

    userImage: {
        height: '20px',
        width: '20px'
    },
    required: {
        color: '#F00'
    },
    dividerStyle: {
        margin: '18px 0 35px',
        color: '#212426',
        fontSize: '28px',
        fontWeight: 700,
        lineHeight: '100%',
    },
    labelText: {
        fontWeight: 500,
        marginBottom: '10px'
    },
    link: {
        color: 'blue',
        textDecoration: 'underline',
        cursor: 'pointer'
    },
    monthlyPlanButton: {
        color: '#ed9125',
        borderRadius: '30px',
        width: "127px",
        height: "40px",
        border: '1px solid #ed9125',
        textTransform: "capitalize",
        fontFamily: "inter",
        fontWeight: 700,
        fontSize: "12px",
        background: "#FBF1D8"
    },
    trailPlanButton: {
        color: '#e34f4f',
        borderRadius: '30px',
        fontWeight: 700,
        fontSize: "13px",
        width: "127px",
        height: "40px",
        background: "#FAE7E9",
        border: '1px solid #e34f4f',
        textTransform: "capitalize",
        fontFamily: "inter",
    },
    annualPlanButton: {
        color: '#799964',
        borderRadius: '30px',
        border: '1px solid #838E66',
        textTransform: "capitalize",
        fontFamily: "inter",
        fontWeight: 700,
        fontSize: "13px",
        width: "127px",
        height: "40px",
        background: "#ECFFED"
    }

})

const DialogContainer = styled(Dialog)({
    "& .MuiTypography-h6": {
        fontWeight: 700,
        fontSize: "35px",
    },
    "& .MuiDialog-paperWidthSm": {
        maxWidth: "700px",
        borderRadius: "8px"
    },
    "& .MuiDialogTitle-root": {
        padding: "0px 50px",
    },
    "& .MuiDialogContent-root": {
        padding: "0px 50px"
    },
    "& .perfectPlanTitle": {
        fontFamily: "inter",
        color: "#212426",
        fontWeight: 700,
        fontSize: "29px",
    },
    "& .blackColor": {
        color: "#000000",
        fontFamily: "inter"
    },
    "& .grayColor": {
        color: "rgba(33, 36, 38, 0.65)",
        fontFamily: "inter"
    },
    "& .perfectPlanSubTitle": {
        fontFamily: "inter",
        color: "rgba(33, 36, 38, 0.65)",
        fontSize: "16px",
    },
    "& .priceRate": {
        fontWeight: 700,
        fontSize: "30px"
    },
    "& .priceRate14pxFontSize": {
        fontSize: "14px",
        fontWeight: "bold"
    },
    "& .packPlanContainer": {
        border: "1px solid #edccb7",
        borderRadius: "8px",
        minWidth: "280px",
        position: "relative",
        padding: "0px 15px",
        cursor: "pointer",
        '&:focus, &:hover': {
            border: "1px solid rgb(254, 127, 45)",
        }
    },
    "& .subscriptionPlanContainer": {
        display: "flex",
        overflowX: "auto",
        gap: "20px",
        alignItems: "stretch",
        scrollBehavior: 'smooth',
        marginTop: "15px",
        padding: "20px 0px",
        marginBottom: "35px"
    },
    "& .annualPack": {
        fontFamily: "inter",
        fontSize: "15px",
        color: "rgba(33, 36, 38, 0.65)", fontWeight: 300, marginTop: "20px"
    },

    "& .offerBag": {
        width: '45px',
        backgroundColor: '#1B5F89',
        color: 'white',
        textAlign: 'center',
        position: 'absolute',
        padding: '8px 0',
        clipPath: 'polygon(0 0, 100% 0, 100% 100%, 50% 70%, 0 100%)',
        right: "27px",
        height: "70px",
        top: "0px"
    },

    "& .packageAmount": {
        fontFamily: "inter",
        fontSize: "14px",
        fontWeight: 300,
    },
    "& .offerText": {
        marginTop: "30px"
    },
    "& .getStartedButton": {
        backgroundColor: "rgb(254, 127, 45)", color: "rgb(255, 255, 255)",
        fontFamily: "inter",
        fontSize: "16px",
        width: "100%",
        height: "40px",
        borderRadius: "20px",
        textTransform: "capitalize",
        margin: "20px 0px",
        cursor: "pointer"
    }
})

const RenewalDialogContainer = styled(Dialog)({
    "& .MuiTypography-h6": {
        fontWeight: 700,
        fontSize: "30px",
        fontFamily: 'Inter',
    },
    "& .MuiDialog-paperWidthSm": {
        maxWidth: "550px",
        borderRadius: "8px"
    },
    "& .MuiDialogTitle-root": {
        padding: "0px 50px",
    },
    "& .MuiDialogContent-root": {
        padding: "0px 50px"
    },
    "& .renewallinkTitle": {
        fontFamily: "inter",
        color: "#212426",
        fontWeight: 700,
        fontSize: "25px",
        marginTop:"30px"
    },
    "& .renewalPlanTitle": {
        fontFamily: "inter",
        color: "rgba(33, 36, 38, 0.65)",
        fontSize: "16px",
        marginBottom: "32px"
    },
    "& .lableNameText": {
        fontFamily: 'Inter',
        fontSize: '16px',
        fontStyle: 'normal',
        color: 'rgba(33, 36, 38, 0.65)',
        marginBottom: '8px',
        lineHeight: '150%',
        fontWeight: 500,
    },
    "& .MuiInputBase-root": {
        border: "1px solid rgba(33, 36, 38, 0.20)",
        backgroundColor: '#ffffff',
        color: '#212426',
        padding: '16px 20px',
        height: '48px',
        borderRadius: '8px',
        fontFamily: 'Inter',
        fontSize: '16px',
        marginBottom: '32px',
        fontWeight: 500,
        lineHeight: '150%',
        fontStyle: 'normal'
    },
    "& .renewalPlanContainer": {
        backgroundColor: "#FFF7ED",
        height: "90px",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        padding: "10px",
        border: "1px solid rgba(33, 36, 38, 0.10)",
        borderRadius: "8px",
        alignItems: "center",
        marginBottom: "32px"
    },
    "& .saveBtn": {
        border: "1px solid #F3813F",
        height: "50px",
        width:"100%",
        borderRadius:"20px",
        backgroundColor:"#FFF",
        color: "#F3813F",
        textTransform:"capitalize",
        "&:hover": {
            backgroundColor: "#F3813F",
            color: "#FFF",
        }
    },
    "& .cancleBtn": {
        border: "1px solid #F3813F",
        height: "50px",
        width: "100%",
        borderRadius: "20px",
        backgroundColor: "#FFF",
        color: "#F3813F",
        textTransform: "capitalize",
        "&:hover": {
            backgroundColor: "#F3813F",
            color: "#FFF",
        }
    },
    "& .planContainer": {
        display: "flex",
        gap: 8,
        justifyContent: "center"
    },
    "& .planTypeBox": {
        width: "23px",
        height: "23px",
        backgroundColor: "white"
    },
    "& .starIcon": {
        color: "#F3813F"
    },
    "& .planTextType": {
        fontFamily: "Inter",
        fontSize: "18px", 
        fontWeight: 500, 
        color: "#36454F"
    },
    "& .planChange": {
        color: "#F3813F",
        fontSize: "15px", 
        marginTop: "8px",
        cursor: "pointer"
    },
    "& .planAmountBox": {
        fontFamily: "Inter", 
        fontSize: "25px", 
        color: "#F3813F", 
        fontWeight: "bold"
    },
    "& .currancyType": {
        fontSize: "15px", 
        fontWeight: 500, 
        marginLeft: "5px"
    },
    "& .priceTypeText": {
        color: "#948B82", 
        fontSize: "15px",
    },
    "& .cardErrorBox": {
        height: "50px", 
        backgroundColor: "#FEF2F2", 
        borderRadius: "6px", 
        display: "flex", 
        alignItems: "center", 
        borderLeft: "6px solid red", 
        marginBottom: "32px"
    },
    "& .errorText": {
        color: "red", 
        textAlign: "center", 
        marginLeft: "17px"
    },
    "& .buttonContainer": {
        display: "flex", 
        justifyContent: "space-between", 
        marginBottom: "40px", 
        gap: 15 
    }
})

const MyProfileWeb = withStyles(ProfileStyles as object)(MyProfile)
export { MyProfileWeb }
// Customizable Area End
