
import {
    Grid, Typography,
    Box, Button, 
} from "@material-ui/core";
import React from "react";
import { primaryColor } from "./theme";
import { makeStyles } from '@material-ui/core/styles';
import { useWindowWidth } from "./utils";
import { Categories, IStep1CET, IStep2CET, IStep3CET, IStep4CET } from "../../blocks/ProductDescription/src/Interface.web";

import { deleteIcon, editIcon, whiteCalendarIcon } from "../../blocks/ProductDescription/src/assets";
import { fileterOption } from "./Step1CET";
import i18next from 'i18next';

interface TripDetailsProps {
    step1CETValues: IStep1CET;
    step2CETValues: IStep2CET;
    step3CETValues: IStep3CET;
    step4CETValues: IStep4CET;
    tripTypeList: Categories[];
    travelerTypeList: Categories[];
    timeList: Categories[];
    navigation: any;
    openModal: () => void;
    gotoTripCalendar: () => void;
}

export function capitalizeFirstLetter(str: string | null | undefined) {
    if (typeof str !== 'string' || str === null || str === undefined) {
        return ''; // or return some default value based on your use case
    }

    return str.charAt(0).toUpperCase() + str.slice(1);
}


  const fileterOptionAsString = (value: any, options: Array<{ label: string; value: string }>) => {
    return options?.filter((option) => option.value?.toString() === value?.toString())?.[0]?.label;
}

const mapApplyDiscount : Record<string, string> = {
    'define_discount_percent': 'Defined discount percent (%)',
    'based_on_purchase_quantity': 'Based on purchase quantity',
    'early_payment_price': 'Early payment price'
}
const mapPaymentMethod: Record<string, string> = {
    'pay_later': 'Pay Later',
    'full_payment': 'Full Payment'
}

const formatDate = (inputDate: string) => {
    const formattedDate = new Date(inputDate).toLocaleString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
      });
      return formattedDate;
}

const useStyles = makeStyles((theme) => ({
    buttonContainer: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: 'flex-end',
        gap: "24px",
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
        },
        [theme.breakpoints.up("md")]: {
            flexDirection: "row", // Keep buttons in a row for medium screens
          },
    },
    button: {
        fontFamily: "Inter",
        fontWeight: 500,
        fontSize: "18px",
        height: "48px",
        fontStyle: 'normal',
        lineHeight: '150%',
        textTransform: "initial",
        [theme.breakpoints.down("sm")]: {
            marginBottom: "16px",
            width: "100%",
        }
    },
    primaryButton: {
        width: '215px',
        padding: '12px 24px',
        whiteSpace: 'nowrap',
        borderRadius: "68px",
        backgroundColor: primaryColor,
        border: `1px solid ${primaryColor}`,
        color: "#ffffff",
        "&:hover": {
            backgroundColor: primaryColor,
            color: '#ffffff'
        }
    },
    secondaryButton: {
        // width: '129px',
        padding: '8px 24px',
        whiteSpace: 'nowrap',
        borderRadius: "55px",
        backgroundColor: "transparent",
        color: '#212426',
        border: `1px solid rgba(33, 36, 38, 0.20)`,
    },
    headingText: {
        fontFamily: "Inter",
        fontSize: "32px",
        color: "#212426",
        fontWeight: 600,
    },
    fieldTitleText: {
        fontFamily: 'Inter',
        fontSize: '18px',
        fontWeight: 700,
        color: 'rgba(33, 36, 38, 0.90)'
    },
    fieldValueText: {
        color: '#212426',
        fontFamily: 'Inter',
        fontSize: '18px',
        fontWeight: 400,
        lineHeight: '150%'

    },
    appliedDiscountText: {
        color: '#212426',
        fontFamily: 'Inter',
        fontSize: '18px',
        fontWeight: 500,
        lineHeight: '150%'
    },
    dropzoneContainer: {
        position: 'relative',
        border: '1px solid rgba(33, 36, 38, 0.20)',
        borderRadius: '18.266px',
        backgroundColor: '#F6F6F6',
        height: '137px',
        width: '137px',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'poiter',
        marginBottom: '16px'
    },
}));





const TripDetailsComponent = ({
    step1CETValues,
    step2CETValues,
    step3CETValues,
    step4CETValues,
    navigation,
    tripTypeList,
    travelerTypeList,
    timeList,
    openModal,
    gotoTripCalendar
}: TripDetailsProps) => {
    const windowWidth: number = useWindowWidth();

    // Define your responsive logic based on windowWidth
    const isMobile: boolean = windowWidth < 600;
    const classes = useStyles();

    console.log({step1CETValues})

    console.log("filter option as string", fileterOptionAsString(step1CETValues.trip_type, tripTypeList))

    const custom_applied_discount = {
        checkbox_value: mapApplyDiscount[step4CETValues.apply_discount],
        defined_discount: step4CETValues.select_discount ? {
            discount: step4CETValues.select_discount,
            start_date: step4CETValues.start_date,
            end_date: step4CETValues.end_date,
        } : null,
        purchase_quantity: step4CETValues.quantity ? {
            title: step4CETValues.title,
            description: step4CETValues?.description,
            quantity: step4CETValues.quantity,
            discounted_price: step4CETValues.discounted_price_per_person_purchase_quantity,
        } : null,
        early_discount: step4CETValues.how_many_days_before_the_trip ? {
            discounted_price: step4CETValues.discounted_price_per_person_early_payment,
            days_before_trip: step4CETValues.how_many_days_before_the_trip
        } : null,
    }

    const custom_group_preference = {
        radio_value: step3CETValues.group_preference,
        private: step3CETValues.group_preference === 'private' ? {
            min_seat: step3CETValues.minimum_seats,
            max_seat: step3CETValues.maximum_seats,
        } : null,
        public: step3CETValues.group_preference === 'public' ? {
            max_seat: step3CETValues.maximum_seats,
        } : null,
    }

    const navigationEdit = () => {
        const id = navigation.getParam("id");
        navigation.navigate("EditTrip", {
            id: id,
        })
    }

    const renderItemValueUI = (item: string, values: any) => {
        console.log("item", item,"values", values)
        if (item === 'main_photo') {
            return (
                <Box
                    className={classes.dropzoneContainer}
                >
                    <img
                        src={values}
                        alt=""
                        style={{ width: '100%', height: '100%', borderRadius: '18.266px' }}
                    />
                </Box>
            );
        } else if (item === 'photos') {
            return (
                <Box
                    style={{
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                        flexWrap: 'wrap',
                        gap: '16px',
                    }}
                >
                    {
                        values.map((src: any, index: number) => {
                            return (
                                <Box
                                    className={classes.dropzoneContainer}
                                    key={index + 'e'}
                                >
                                    <img
                                        src={src}
                                        alt=""
                                        style={{ width: '100%', height: '100%', borderRadius: '18.266px' }}
                                    />
                                </Box>
                            )
                        })
                    }
                </Box>
            );
        } else if (item === 'applied_discount') {
            return (
                <Box style={{ display: 'flex', gap: '22px' }}>
                    <Typography
                        className={classes.appliedDiscountText}
                    >
                        {capitalizeFirstLetter(values?.checkbox_value)}
                    </Typography>
                    <Box style={{ display: 'flex', flexDirection: 'column' }}>
                        {
                            values?.defined_discount && (
                                <>
                                    <Typography className={classes.fieldValueText}>{i18next.t('travelAgency.singleTripDetail.applicableDiscount')} : <span style={{ color: primaryColor }}>{values?.defined_discount?.discount}%</span></Typography>
                                    <Typography className={classes.fieldValueText}>{i18next.t('travelAgency.singleTripDetail.startDate')} : {formatDate(values?.defined_discount?.start_date)}</Typography>
                                    <Typography className={classes.fieldValueText}>{i18next.t('travelAgency.singleTripDetail.endDate')} : {formatDate(values?.defined_discount?.end_date)}</Typography>
                                </>
                            )

                        }
                        {
                            values?.purchase_quantity && (
                                <>
                                    <Typography className={classes.fieldValueText}>{i18next.t('travelAgency.singleTripDetail.title')} : {values?.purchase_quantity?.title}</Typography>
                                    <Typography className={classes.fieldValueText}>{i18next.t('travelAgency.singleTripDetail.description')} : {values?.purchase_quantity?.description}</Typography>
                                    <Typography className={classes.fieldValueText}>{i18next.t('travelAgency.singleTripDetail.quantity')} : {values?.purchase_quantity?.quantity}</Typography>
                                    <Typography className={classes.fieldValueText}>{i18next.t('travelAgency.singleTripDetail.discountedPricePerPerson')} : {values?.purchase_quantity?.discounted_price}</Typography>

                                </>
                            )
                        }
                        {
                            values?.early_discount && (
                                <>
                                    <Typography className={classes.fieldValueText}>{i18next.t('travelAgency.singleTripDetail.discountedPricePerPerson')} : <span style={{ color: primaryColor }}>{values?.early_discount?.discounted_price}</span></Typography>
                                    <Typography className={classes.fieldValueText}>{i18next.t('travelAgency.singleTripDetail.howManyDaysBeforeTheTrip')} : {values?.early_discount?.days_before_trip}</Typography>
                                </>
                            )
                        }
                    </Box>
                </Box>
            );
        } else if (item === 'group_preference') {
            console.log("group_preference values", values)
            return (
                <Box>
                    <Typography
                        className={classes.fieldValueText}
                    >
                        {capitalizeFirstLetter(values?.radio_value)}
                    </Typography>
                    {
                        values?.private && (
                            <Box style={{ display: 'flex', flexDirection: 'row', gap: '82px', alignItems: 'center' }}>
                                <Typography className={classes.fieldValueText}>Minimum Seats : {values.private?.min_seat}</Typography>
                                <Typography className={classes.fieldValueText}>Maximum Seats : {values.private?.max_seat}</Typography>
                            </Box>
                        )

                    }
                    {
                        values?.public && (
                            <>
                                <Typography className={classes.fieldValueText}>Maximum Seats: {values.public?.max_seat}</Typography>
                            </>
                        )
                    }

                </Box>
            );
        } else if (item === 'itinerary') {
            return (
                <Box>
                    {
                        values?.map((item: any) => {
                            return (
                                <Typography
                                    key={item.serial}
                                    className={classes.fieldValueText}
                                >
                                    {item?.serial}. {item?.value}
                                </Typography>
                            )
                        })
                    }
                </Box>
            )
        } else if (item === 'vibes' || item == 'language') {
            return (
                <Box>
                    {
                        values?.map((item: any, index: number) => {
                            return (
                                <span
                                    key={index}
                                    className={classes.fieldValueText}
                                >
                                    {capitalizeFirstLetter(item)}{index + 1 < values.length ? ', ' : ''}
                                </span>
                            )
                        })
                    }
                </Box>
            )
        } else {
            console.log("single values", values)
            return (
                <Typography
                    className={classes.fieldValueText}
                >
                    {capitalizeFirstLetter(values)}
                </Typography>
            );
        }
    };

    const renderEachElement = (title: string, fieldName: string, fieldValue: any) => {
            return (
                <Grid container spacing={3} style={{ marginTop: '36px' }}>
                    <Grid item md={2} sm={3} xs={12}>
                        <Typography className={classes.fieldTitleText}>
                            {title}
                        </Typography>
                    </Grid>
                    <Grid item md={10} sm={9} xs={12}>
                        {renderItemValueUI(fieldName, fieldValue)}
                    </Grid>
                </Grid>
            )
    }


    return (
        <div
            style={{
                width: "100%",
                margin: "0 auto",
                padding: "72px",
                backgroundColor: "#ffffff",
                borderRadius: "20px",
                boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.04)",
            }}
        >
            <Grid container>
                <Grid item md={5} xs={12}>
                    <Typography
                        className={classes.headingText}
                    >
                        {step1CETValues.title}
                    </Typography>
                </Grid>
                <Grid item md={7} xs={12}>
                    <div className={classes.buttonContainer}>
                        <Button
                            style={{width: '129px'}}
                            className={`${classes.button} ${classes.secondaryButton}`}
                            startIcon={<img src={editIcon} style={{ height: "100%", width: "100%" }} alt="" />}
                            onClick={() => navigationEdit()}
                        >
                            {i18next.t('travelAgency.singleTripDetail.edit')}   
                        </Button>
                        <Button
                            style={{width: '140px'}}
                            className={`${classes.button} ${classes.secondaryButton}`}
                            startIcon={<img src={deleteIcon} style={{ height: "100%", width: "100%" }} alt="" />}
                            onClick={openModal}
                        >
                        {i18next.t('travelAgency.singleTripDetail.delete')}   
                     </Button>
                    </div>
                </Grid>
            </Grid>

            {renderEachElement(i18next.t('travelAgency.singleTripDetail.mainPhoto'), 'main_photo', step1CETValues.main_photo)}
            {renderEachElement(i18next.t('travelAgency.singleTripDetail.photos'), 'photos', step1CETValues.photos)}
            {renderEachElement(i18next.t('travelAgency.singleTripDetail.description'), 'description', step1CETValues.description)}
            {renderEachElement(i18next.t('travelAgency.singleTripDetail.pricePerPerson'), 'price_per_person', step4CETValues.price_per_person + ' ' + step4CETValues.currency)}
            {renderEachElement(i18next.t('travelAgency.singleTripDetail.paymentMethod'), 'payment_mathod', mapPaymentMethod[step4CETValues.payment_method])}
            {renderEachElement(i18next.t('travelAgency.singleTripDetail.appliedDiscount'), 'applied_discount', custom_applied_discount)}
            {renderEachElement(i18next.t('travelAgency.singleTripDetail.tripType'), 'trip_type', fileterOptionAsString(step1CETValues.trip_type, tripTypeList))}
            {renderEachElement(i18next.t('travelAgency.singleTripDetail.country'), 'country', step1CETValues.country)}
            {renderEachElement(i18next.t('travelAgency.singleTripDetail.city'), 'city', step1CETValues.city)}
            {renderEachElement(i18next.t('travelAgency.singleTripDetail.area'), 'area', step1CETValues.area)}
            {renderEachElement(i18next.t('travelAgency.singleTripDetail.vibes'), 'vibes', step1CETValues.vibes)}
            {renderEachElement(i18next.t('travelAgency.singleTripDetail.time'), 'time', fileterOptionAsString(step1CETValues.time, timeList))}
            {renderEachElement(i18next.t('travelAgency.singleTripDetail.duration'), 'duration', step1CETValues.duration)}
            {renderEachElement(i18next.t('travelAgency.singleTripDetail.guidedTour'), 'guided_tour', step2CETValues.guided_tour === 'false' ? 'No' : 'Yes')}
            {renderEachElement(i18next.t('travelAgency.singleTripDetail.itinerary'), 'itinerary', step2CETValues.itinerary)}
            {renderEachElement(i18next.t('travelAgency.singleTripDetail.facilities'), 'facilities', step2CETValues.facilities)}
            {renderEachElement(i18next.t('travelAgency.singleTripDetail.whatToBring'), 'what_to_bring', step2CETValues.what_to_bring)}
            {renderEachElement(i18next.t('travelAgency.singleTripDetail.transportation'), 'transportation', step2CETValues.transportation === 'false' ? 'Not Provided' : 'Provided')}
            {renderEachElement(i18next.t('travelAgency.singleTripDetail.include'), 'include', step2CETValues.include)}
            {renderEachElement(i18next.t('travelAgency.singleTripDetail.doesntInclude'), 'doed_not_include', step2CETValues.does_not_include)}
            {renderEachElement(i18next.t('travelAgency.singleTripDetail.notAllowed'), 'not_allowed', step2CETValues.not_allowed)}
            {renderEachElement(i18next.t('travelAgency.singleTripDetail.moreInformation'), 'more_information', step2CETValues.more_information)}
            {renderEachElement(i18next.t('travelAgency.singleTripDetail.travelerType'), 'traveler_type', fileterOptionAsString(step3CETValues.traveler_type, travelerTypeList))}
            {renderEachElement(i18next.t('travelAgency.singleTripDetail.groupPreference'), 'group_preference', custom_group_preference)}
            {renderEachElement(i18next.t('travelAgency.singleTripDetail.dressCode'), 'dress_code', step3CETValues.dress_code)}
            {renderEachElement(i18next.t('travelAgency.singleTripDetail.language'), 'language', step3CETValues.language)}
            {renderEachElement(i18next.t('travelAgency.singleTripDetail.kidsFriendly'), 'kind_friendly', step3CETValues.kids_friendly === 'false' ? 'No' : 'Yes')}
            {renderEachElement(i18next.t('travelAgency.singleTripDetail.femaleOnly'), 'female_only', step3CETValues.female_only === 'false' ? 'No' : 'Yes')}
            {renderEachElement(i18next.t('travelAgency.singleTripDetail.wheelchairAccess'), 'wheelchair_access', step3CETValues.wheelchair_access === 'false' ? 'No' : 'Yes')}
            {renderEachElement(i18next.t('travelAgency.singleTripDetail.cancellationPolicyNoShow'), 'cancellation_policy', step4CETValues.cancellation_policy_or_no_show)}



        </div>
    )
}

export default TripDetailsComponent;