
import React, { useState, useEffect } from "react";
import {
  TextField,
  MenuItem,
  FormControl,
  InputAdornment,
  IconButton,
  Typography,
} from "@material-ui/core";
import Check from "@material-ui/icons/Check"
import { primaryColor } from "./theme";
import { CountryData } from "../../blocks/email-account-registration/src/Interface.web";
import i18next from "i18next";

interface CountryCode {
  alpha_code: string;
  name: string;
  flag_emoji: string;
  country_code: string;
  phone_number_length: number[];
  id: string;
  
}

interface PhoneInputProps {
  phoneError: string;
  isPhoneNumberValid: boolean;
  touchedPhone: boolean;
  phoneErrorMsg: string;
  isPhoneVarified: boolean;
  handleValueChange: (field: string, value: string) => void;
  setFullPhoneNumber: (field: string, value: string) => void;
  phoneValue: string;
  sendEmailOrSmsOtpRequest: (field: string, button: string) => void;
  countryData: CountryData[];
  country_code?: string;
  planItineraryPhn?: boolean;
  handleCountryCodeChanges?:(value:string)=>void
}

const PhoneInput = ({
  phoneError,
  phoneErrorMsg,
  touchedPhone,
  isPhoneNumberValid,
  setFullPhoneNumber,
  handleValueChange,
  phoneValue,
  sendEmailOrSmsOtpRequest,
  countryData,
  isPhoneVarified,
  planItineraryPhn,
  country_code = "",
  handleCountryCodeChanges
}: PhoneInputProps) => {
  const [selectedCountryCode, setSelectedCountryCode] = useState("");
  const [selectedCountryId, setSelectedCountryId] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [touchPhone, setTouchPhone] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");


  useEffect(() => {
    if (country_code) {
      const pluscode = `+${country_code}`
      // console.log("countryCodeData", pluscode)

      const selectedCountry = countryData.find(country => {
        // console.log(country.country_code, "countryCodeData")
        return country.country_code === pluscode
      });
      if(selectedCountry?.id) {
        setSelectedCountryId(selectedCountry?.id)
      }
    }else {
      setSelectedCountryId(countryData[0]?.id)
    }
  }, [countryData, country_code]);

  useEffect(() => {
    if (phoneValue) {
      setPhoneNumber(phoneValue)
    }
  }, [phoneValue])

  const handleCountryCodeChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setSelectedCountryId(event.target.value as string);
    const selectedCountry = countryData.find(country => country.id === (event.target.value as string));
    if (selectedCountry) {
      country_code=selectedCountry.country_code
      if (handleCountryCodeChanges) {
        handleCountryCodeChanges(selectedCountry.country_code);
      }      
      setSelectedCountryCode(selectedCountry.country_code);
    }
  };

  const handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputPhoneNumber = event.target.value;

    const validation = validatePhoneNumber(inputPhoneNumber, selectedCountryCode);
        
    if (!validation.isValid) {
        setErrorMessage(validation.message);
        setIsValid(false);
    } else {
        setErrorMessage("");
        setIsValid(true);
    }
    setPhoneNumber(inputPhoneNumber);
    setFullPhoneNumber("phoneNumber", selectedCountryCode + inputPhoneNumber);
    handleValueChange("mobile", selectedCountryCode + " " + inputPhoneNumber);
    setFullPhoneNumber("onlyPhone", inputPhoneNumber);
    setTouchPhone(true);
  };

  const isErrorPhoneNumber =
    !phoneNumber && touchPhone ||
    !phoneNumber && touchedPhone ||
    !!phoneError && touchedPhone ||
    !!phoneError && touchPhone ||
    !!phoneErrorMsg ||
    !isPhoneNumberValid && touchPhone ||
    !isPhoneNumberValid && touchedPhone ||
    !isValid;

    function validatePhoneNumber(phoneNumber:any, countryCode:any) {
      const country = countryData.find(
          (item:any) => item.country_code === countryCode
      );
    
  
      if (!country?.phone_number_length) {
        return { isValid: false, message: "Invalid country code." };
    }
    
    if (!country?.phone_number_length.includes(phoneNumber.length)) {
        return { isValid: false, message: `Phone number should be ${country?.phone_number_length.join(" or ")} digits.` };
    }
    
    return { isValid: true, message: "" };
  }

  return (
    <FormControl variant="outlined" fullWidth>
      <div style={{
        display: "flex",
        alignItems: "center",
        border: `1px solid`,
        borderColor: isErrorPhoneNumber ? 'red' : '#C3CAD9',
        borderRadius: '5px',
      }}>
        <div>
          <TextField
            select
            variant="standard"
            InputProps={{
              disableUnderline: true,
              style: {
                backgroundColor: "#ffffff",
                color: "rgba(33, 36, 38, 0.90)",
                border: `none`,
                borderRight: `1px solid #C3CAD9`,
                borderRadius: "5px 0px 0px 5px",
                fontFamily: "Inter",
                fontWeight: 500,
                minWidth: "108px",
                height:'55px'
              },
            }}
            SelectProps={{
              MenuProps: {
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
                PaperProps: {
                  style: {
                    maxHeight: "250px", // Limit height and add scrolling
                  },
                },
              },
            }}
            value={selectedCountryId}
            onChange={handleCountryCodeChange}
          >
            {countryData.map((option) => (
              <MenuItem
                key={option.id}
                value={option.id}
                style={{
                  whiteSpace: 'normal',
                  color: 'rgb(33 36 38 / 50%)',
                  fontFamily: 'Inter',
                  fontSize: '16px',
                  fontWeight: 500,
                  lineHeight: '150%'
                }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                  <div>
                  {option.country_code}  
                  </div>
                  <div>
                    <img
                      width={28} height={18} style={{ borderRadius: '4px' }}
                      src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${option.alpha_code}.svg`}
                    />
                  </div>
                </div>
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div style={{ width: '100%' }}>
          <TextField
            fullWidth
            type="tel"
            variant="standard"
            placeholder={i18next.t('travelAgency.myProfile.entermobileNumber')}
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            InputProps={{
              style: {
                backgroundColor: "#ffffff",
                color: "rgba(33, 36, 38, 0.90)",
                paddingLeft: "10px",
                border: `none`,
                borderRadius: "0px 5px 5px 0px",
                fontFamily: "Inter",
                fontWeight: 500
              },
              disableUnderline: true,
              endAdornment: planItineraryPhn ? null : isPhoneVarified ? (
                <InputAdornment position='end'>
                  <div style={{ display: 'flex', gap: '5px' ,marginRight:'10px'}}>
                    <Check style={{ color: '#6F964C', fontSize: '20px', fontWeight: 600 }} />
                    <Typography style={{ color: '#6F964C', fontSize: '14px', fontFamily: "Inter", fontWeight: 600 }}>Verified</Typography>
                  </div>
                </InputAdornment>
              ) : (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => sendEmailOrSmsOtpRequest('sms', 'verify')}
                    style={{ fontSize: "14px", fontFamily: "Inter", fontWeight: 500, color: primaryColor }}
                    disabled={isErrorPhoneNumber || !phoneNumber}>
                    {i18next.t('travelAgency.myProfile.verify')}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
      {
        isErrorPhoneNumber && (
          <Typography
            variant="caption"
            color="error"
            style={{
              fontFamily: "Inter",
              fontSize: '12px',
              fontWeight: 400,
              marginTop: planItineraryPhn ? '8px' : '4px',
            }}
          >
            {!phoneNumber && (touchPhone && phoneNumber.length === 0 || !phoneNumber && touchedPhone)
              ? i18next.t('itinerary.requiredPhoneNumber')
              : (!isPhoneNumberValid && (touchPhone || touchedPhone)
                ? i18next.t('itinerary.validPhoneNumber')
                : phoneErrorMsg
                  ? `* ${phoneErrorMsg}`
                  : (!!phoneError && (touchPhone || touchedPhone)
                    ? phoneError
                    : ""))
            }
          </Typography>
        )
      }
    </FormControl>
  );
};

export default PhoneInput;