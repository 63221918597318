
import {
    Grid, Typography, Box, Button, Divider, createTheme, ThemeProvider
} from "@material-ui/core";
import React from "react";

import * as Yup from "yup";
import { Formik, Form } from 'formik'
import { primaryColor } from "./theme";
import { makeStyles } from '@material-ui/core/styles';
import { useWindowWidth } from "./utils";
import { ICurrency, IStep4CET } from "../../blocks/ProductDescription/src/Interface.web";
import CustomRadioGroup from "./CustomRadioGroup";
import CustomTextField from "./CustomTextField";

import CustomApplyDiscount from "./CustomApplyDiscount";
import CurrencyTextField from "./CurrencyTextField";
import i18next from 'i18next';

interface Step4CETProps {
    initialState: IStep4CET;
    submitStep4CET: (values: IStep4CET) => void;
    backStep4CET: (values: IStep4CET) => void;
    cancelTrip: () => void;
    currencyList: ICurrency[];
    mode: 'create' | 'edit';
}


const validationSchema = Yup.object().shape({
    payment_method: Yup.string().required(() => i18next.t('travelAgency.editTrip.pleaseSelectPaymentMethodForTheTrip')),
    price_per_person: Yup.string().required(() => i18next.t('travelAgency.editTrip.pleaseEnterPricePerPersonForTheTrip')),
    cancellation_policy_or_no_show: Yup.string().required(() => i18next.t('travelAgency.editTrip.pleaseSelectPaymentMethodForTheTrip')),

    select_discount: Yup.string()
        .test('conditional-validation', () => i18next.t('travelAgency.editTrip.pleaseEnterDiscountPercentForTheCoupon'), function (value) {
            const apply_discount = this.resolve(Yup.ref('apply_discount'));

            if (apply_discount === "define_discount_percent") {
                return !!value;
            }

            return true;
        }),
    start_date: Yup.string()
        .test('conditional-validation',() => i18next.t('travelAgency.editTrip.pleaseSelectStartDateForTheDiscountedPrice'), function (value) {
            const apply_discount = this.resolve(Yup.ref('apply_discount'));

            if (apply_discount === "define_discount_percent") {
                return !!value;
            }

            return true;
        }),
    end_date: Yup.string()
        .test('conditional-validation',() => i18next.t('travelAgency.editTrip.pleaseSelectEndDateForTheDiscountedPrice'), function (value) {
            const apply_discount = this.resolve(Yup.ref('apply_discount'));

            if (apply_discount === "define_discount_percent") {
                return !!value;
            }

            return true;
        }),

    title: Yup.string()
        .test('conditional-validation',() => i18next.t('travelAgency.editTrip.pleaseEnterTitleForTheCoupon'), function (value) {
            const apply_discount = this.resolve(Yup.ref('apply_discount'));

            if (apply_discount === "based_on_purchase_quantity") {
                return !!value;
            }

            return true;
        }),
    description: Yup.string().notRequired(),
    quantity: Yup.string()
        .test('conditional-validation', () => i18next.t('travelAgency.editTrip.pleaseEnterPurchaseQuantityForCoupon'), function (value) {
            const apply_discount = this.resolve(Yup.ref('apply_discount'));

            if (apply_discount === "based_on_purchase_quantity") {
                return !!value;
            }

            return true;
        }),
    discounted_price_per_person_purchase_quantity: Yup.string()
        .test('conditional-validation',() => i18next.t('travelAgency.editTrip.pleaseEnterPurchaseQuantityForCoupon'), function (value) {
            const apply_discount = this.resolve(Yup.ref('apply_discount'));

            if (apply_discount === "based_on_purchase_quantity") {
                return !!value;
            }

            return true;
        }),

    discounted_price_per_person_early_payment: Yup.string()
        .test('conditional-validation',() => i18next.t('travelAgency.editTrip.pleaseEnterPricePerPersonForCoupon'), function (value) {
            const apply_discount = this.resolve(Yup.ref('apply_discount'));

            if (apply_discount === "early_payment_price") {
                return !!value;
            }

            return true;
        }),
    how_many_days_before_the_trip: Yup.string()
        .test('conditional-validation',() => i18next.t('travelAgency.editTrip.pleaseMentionNumberOfDays'), function (value) {
            const apply_discount = this.resolve(Yup.ref('apply_discount'));

            if (apply_discount === "early_payment_price") {
                return !!value;
            }

            return true;
        }),
})



const optionsPayment = [
    { label: 'Full Payment  (Coming Soon)', value: 'full_payment' },
    { label: 'Pay Later', value: 'pay_later' },
];

const optionsApplyDiscount = [
    { label: 'Define discount percent (%)', value: "define_discount_percent" },
    { label: 'Based on purchase quantity', value: 'based_on_purchased_quantity' },
    { label: 'Early payment price', value: 'early_payment_price' },
]

// Create a custom theme
const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 768,
            md: 1024, // Change the default md width to 1024
            lg: 1280,
            xl: 1920,
        },
    },
});




const Step4CET = ({
    initialState,
    submitStep4CET,
    backStep4CET,
    cancelTrip,
    currencyList,
    mode
}: Step4CETProps) => {
    const windowWidth: number = useWindowWidth();

    // Define your responsive logic based on windowWidth
    const isMobile: boolean = windowWidth < 768;

    const useStyles = makeStyles((theme) => ({
        customFont: {
            fontFamily: 'Inter', // Replace with your desired font family
            fontWeight: 400,
        },
        buttonSelectStyle: {
            fontFamily: 'Inter', // Replace with your desired font family
            fontWeight: 500,
            color: 'rgba(33, 36, 38, 0.90)',
            fontSize: '16px',
            marginTop: '-22px'
        },
        toastModal: {
            borderRadius: '8px',
            display: 'inline-flex',
            height: '45px',
            alignItems: 'center',
            gap: '12px',
            flexShrink: 0,
            backgroundColor: '#FFF2F2',
            borderLeft: '8px solid #FF0000',
            padding: '8px 16px 8px 12px',
            margin: '30px 0'
        },
        formHelperText: {
            color: 'rgb(244, 67, 54)',
            fontSize: '12px',
            marginLeft: isMobile ? '0px' : '20px',
            marginTop: isMobile ? '8px' : '24px'
        },
        headingText: {
            fontSize: '32px',
            fontFamily: 'Inter',
            fontWeight: 600,
            fontStyle: 'normal',
            color: '#212426',
            lineHeight: 'normal'
        },
        stepText: {
            fontSize: '16px',
            fontFamily: 'Inter',
            fontWeight: 600,
            color: "#FE7F2D",
            lineHeight: '150%',
            marginBottom: '2px'
        },
        fieldTitleText: {
            fontFamily: 'Inter',
            fontSize: '18px',
            color: 'rgba(33, 36, 38, 0.90)',
            marginTop: '8px',
            fontWeight: 500,
            lineHeight: '150%',
            fontStyle: 'normal'
        },
        whiteContainer: {
            padding: "47px 71px 71px 71px",
            borderRadius: '20px',
        },
        cancelBtn: {
            color: primaryColor,
            textTransform: 'initial',
            fontSize: '16px',
            fontFamily: "Inter",
            fontWeight: 500,
            lineHeight: '150%',
            fontStyle: 'normal',
            paddingLeft: 0,
        },
        nxtBtn: {
            backgroundColor: primaryColor,
            color: '#ffffff',
            textTransform: 'initial',
            borderRadius: '68px',
            padding: '10px 50px',
            fontSize: '16px',
            fontFamily: "Inter",
            fontWeight: 500,
            width: '189px',
            height: '56px',
            whiteSpace: 'nowrap',
            '&:hover': {
                backgroundColor: primaryColor,
                color: '#ffffff',
            }
        },
        bckBtn: {
            backgroundColor: 'transparent',
            color: primaryColor,
            border: `1px solid ${primaryColor}`,
            textTransform: 'initial',
            borderRadius: '68px',
            padding: '10px 50px',
            fontSize: '16px',
            fontFamily: "Inter",
            fontWeight: 500,
            width: '189px',
            height: '56px',
            '&:hover': {
                backgroundColor: 'transparent',
                color: primaryColor,
            }
        },
        distanceBtwnRdo: {
            marginTop: '48px'
        },
        distanceBtnDBtn: {
            marginTop: '48px'
        },
        BtwnBtn: {
            display: 'flex',
            gap: '30px',
            justifyContent: "center"
        },
        [theme.breakpoints.down(1024)]: {
            whiteContainer: {
                padding: "36px 47px 31px 47px",
                borderRadius: '20px',
                margin: '48px'
            },
            headingText: {
                fontSize: '26px',
                fontFamily: 'Inter',
                fontWeight: 600,
                fontStyle: 'normal',
                color: '#212426',
                lineHeight: 'normal'
            },
            fieldTitleText: {
                fontFamily: 'Inter',
                fontSize: '16px',
                color: 'rgba(33, 36, 38, 0.90)',
                marginTop: '8px',
                fontWeight: 500,
                lineHeight: '150%',
                fontStyle: 'normal'
            },
            nxtBtn: {
                backgroundColor: primaryColor,
                color: '#ffffff',
                textTransform: 'initial',
                borderRadius: '68px',
                padding: '10px 48px',
                fontSize: '16px',
                fontFamily: "Inter",
                fontWeight: 500,
                width: '132px',
                height: '44px'
            },
            bckBtn: {
                backgroundColor: 'transparent',
                color: primaryColor,
                border: `1px solid ${primaryColor}`,
                textTransform: 'initial',
                borderRadius: '34px',
                padding: '10px 48px',
                fontSize: '16px',
                fontFamily: "Inter",
                fontWeight: 500,
                width: '132px',
                height: '44px'
            },
            distanceBtwnRdo: {
                marginTop: '40px'
            },
            distanceBtnDBtn: {
                marginTop: '32px'
            },
            BtwnBtn: {
                display: 'flex',
                gap: '16px',
                justifyContent: "center"
            },

        },
        [theme.breakpoints.down(768)]: {
            whiteContainer: {
                padding: "15px 15px 17px 15px",
                borderRadius: '12px',
                margin: '16px'
            },
            headingText: {
                fontSize: '18px',
                fontFamily: 'Inter',
                fontWeight: 600,
                fontStyle: 'normal',
                color: '#212426',
                lineHeight: 'normal'
            },
            stepText: {
                fontSize: '12px',
                fontFamily: 'Inter',
                fontWeight: 500,
                color: "#FE7F2D",
                lineHeight: '150%',
            },
            fieldTitleText: {
                fontFamily: 'Inter',
                fontSize: '14px',
                color: 'rgba(33, 36, 38, 0.90)',
                marginTop: '8px',
                fontWeight: 500,
                lineHeight: '150%',
                fontStyle: 'normal'
            },
            cancelBtn: {
                color: primaryColor,
                textTransform: 'initial',
                fontSize: '14px',
                fontFamily: "Inter",
                fontWeight: 500,
                paddingLeft: 0,
            },
            nxtBtn: {
                backgroundColor: primaryColor,
                color: '#ffffff',
                textTransform: 'initial',
                borderRadius: '68px',
                padding: '10px 48px',
                fontSize: '16px',
                fontFamily: "Inter",
                fontWeight: 500,
                width: '140px',
                height: '44px'
            },
            bckBtn: {
                backgroundColor: 'transparent',
                color: primaryColor,
                border: `1px solid ${primaryColor}`,
                borderRadius: '68px',
                textTransform: 'initial',
                padding: '10px 48px',
                fontSize: '16px',
                fontFamily: "Inter",
                fontWeight: 500,
                width: '140px',
                height: '44px'
            },
            distanceBtwnRdo: {
                marginTop: '24px'
            },
            distanceBtnDBtn: {
                marginTop: '24px'
            },
            BtwnBtn: {
                display: 'flex',
                gap: '15px',
                justifyContent: "space-between"
            },

        },
    }));
    const classes = useStyles();

    return (
        <div
            className={classes.whiteContainer}
            style={{
                maxWidth: "1082px",
                minWidth: "328px",
                backgroundColor: '#fff',
                border: '0.5px solid rgba(33, 36, 38, 0.20)',
                boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.04)',
                margin: isMobile ? '0 auto' : '0'
            }}
        >
            <ThemeProvider theme={theme}>
                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                    <Typography className={classes.headingText}>{mode === "create" ? i18next.t('createTrip.planNewTrip') : i18next.t('createTrip.editTripDetails')}</Typography>
                    <Typography className={classes.stepText}>{i18next.t('travelAgency.editTrip.step')} 4 - 4</Typography>
                </Box>
                <Formik
                    enableReinitialize={true}
                    initialValues={initialState}
                    test-id="submitButtonId"
                    onSubmit={submitStep4CET}
                    validationSchema={validationSchema}
                >
                    {({ values, setFieldValue, errors, touched }) => {
                        return (
                            <Form style={{marginBottom: '0px'}}>
                                <Box>
                                    <Grid container className={classes.distanceBtwnRdo}  spacing={2}>
                                        <Grid item md={3} sm={3} xs={5}>
                                            <Typography
                                                className={classes.fieldTitleText}
                                            >
                                               {i18next.t('travelAgency.editTrip.paymentMethod')} <span style={{ color: 'red' }}> *</span>
                                            </Typography>

                                        </Grid>
                                        <Grid item md={4} sm={9} xs={7}>
                                            <CustomRadioGroup
                                                fieldName= "payment_method"
                                                options={optionsPayment}
                                                error={errors.payment_method as string}
                                                value={values.payment_method}
                                                onChange={setFieldValue}
                                                touched={touched.payment_method as boolean}
                                            />
                                        </Grid>

                                    </Grid>
                                    <Typography variant="caption" style={{ fontFamily: "Inter", fontWeight: 400, marginTop: '-2px', color: '#FF0000' }}>
                                        {touched.payment_method ? errors.payment_method : ""}
                                    </Typography>

                                    <Grid container className={classes.distanceBtwnRdo} spacing={2}>
                                        <Grid item md={3} sm={3} xs={5}>
                                            <Typography
                                                className={classes.fieldTitleText}
                                            >
                                                {i18next.t('travelAgency.editTrip.pricePer')} <span style={{whiteSpace: 'nowrap'}}>{i18next.t('travelAgency.editTrip.Person')}<span style={{ color: 'red' }}> *</span></span>
                                            </Typography>
                                        </Grid>
                                        <Grid item md={5} sm={9} xs={7}>
                                            <CurrencyTextField
                                                currency={values.currency}
                                                currencyList={currencyList}
                                                value={values.price_per_person}
                                                priceFieldName="price_per_person"
                                                onChange={setFieldValue}
                                                error={errors.price_per_person as string}
                                                touch={touched.price_per_person as boolean}
                                            />
                                        </Grid>
                                    </Grid>




                                    <Grid container style={{ marginTop: '32px' }}>
                                        <Grid item md={12} xs={12}>
                                            <CustomTextField
                                                fieldTitle={i18next.t('travelAgency.editTrip.cancellationPolicyNoShow')} 
                                                placeholder={'Write something...'}
                                                value={values.cancellation_policy_or_no_show}
                                                fieldName="cancellation_policy_or_no_show"
                                                onChange={setFieldValue}
                                                error={errors.cancellation_policy_or_no_show as string}
                                                touched={touched.cancellation_policy_or_no_show as boolean}
                                                multiline={true}
                                                maxRows={7}
                                                minRows={4}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container className={classes.distanceBtwnRdo} spacing={2}>
                                        <Grid item md={3} sm={3} xs={5}>
                                            <Typography
                                                className={classes.fieldTitleText}
                                            >
                                                {i18next.t('travelAgency.editTrip.applyDiscount')} 
                                            </Typography>
                                        </Grid>
                                        <Grid item md={9} sm={9} xs={7}>
                                            <CustomApplyDiscount
                                                values={values}
                                                setFieldValue={setFieldValue}
                                                errors={errors}
                                                touched={touched}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Divider className={classes.distanceBtwnRdo} />
                                </Box>

                                <Box
                                    className={classes.distanceBtnDBtn}
                                    style={{ display: 'flex', justifyContent: 'space-between', flexDirection: isMobile ? 'column-reverse' : "row", gap: '42px' }}>
                                    <Button
                                        className={classes.cancelBtn}
                                        onClick={() => cancelTrip()}
                                    >
                                        {i18next.t('travelAgency.editTrip.cancel')} 
                                    </Button>
                                    <Box
                                       className={classes.BtwnBtn}
                                    >
                                        <Button
                                            className={classes.bckBtn}
                                            onClick={() => backStep4CET(values)}
                                        >
                                            {i18next.t('travelAgency.editTrip.back')} 
                                        </Button>
                                        <Button
                                            className={classes.nxtBtn}
                                            type="submit"
                                        >
                                            {mode === 'edit' ? i18next.t('travelAgency.editTrip.saveChanges') : i18next.t('travelAgency.editTrip.submit')} 
                                        </Button>
                                    </Box>
                                </Box>
                            </Form>)
                    }}
                </Formik>
            </ThemeProvider>
        </div>
    )
}

export default Step4CET;