 // Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

import { runEngine } from "../../../framework/src/RunEngine";
import { AuthContext } from "../../user-profile-basic/src/AuthContext.web";
import { getAuthToken } from "../../../components/src/utils";


export const configJSON = require("./config");
  // Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?:any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  order_id: any;
  catalogue_id: number;
  quantity: number;
  taxable: boolean;
  taxable_value: number;
  token: string;
  orderList: any;
  orderItems: any;
  isVisible: boolean;
  isRefreshing: boolean;

  id: any;
  name: string;
  description: string;
  price: number;
  currency: string;
  category_id: number;
  created_at: string;
  updated_at: string;
  account_id: number;
  trendingList: any;
  isDeletecart : boolean,
  similarTripExperience :any,
  isSucess :boolean,
  responseStatusModal:boolean,
  isRemovePopupOpen:boolean,
  elem:any,
  paymentFlow:boolean
  paymentResponse: any;
  paymentStatus:boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ShoppingCartOrdersController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getOrdersApiCallId: any;
  showOrderApiCallId: any;
  createOrderItemApiCallId: any;
  deleteOrderItemApiCallId: any;
  deleteCartItemApiCallId :any;
  similerTripExperienceApiCallID :any
  apiCallIdBookMyTrip: string = '';
  static contextType = AuthContext
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      order_id: 0,
      catalogue_id: 0,
      quantity: 0,
      taxable: false,
      taxable_value: 0,
      token: "",
      orderList: [],
      orderItems: [],
      isVisible: false,
      isRefreshing: false,

      id: 0,
      name: "",
      description: "",
      price: 0,
      currency: "",
      category_id: 0,
      created_at: "",
      updated_at: "",
      account_id: 0,
      trendingList: [],
      isDeletecart : false,
      similarTripExperience : [],
      isSucess :false,
      responseStatusModal:false,
      isRemovePopupOpen:false,
      elem :{},
      paymentFlow:false,
      paymentResponse: {},
      paymentStatus:false
      // Customizable Area End
    };

    // Customizable Area Start
   
    
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    this.getSimilerTripExperience()
    // Customizable Area End
  }

  receive = async (from: String, message: Message) => {
    // Customizable Area Start
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
      this.getOrders(token);
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getOrdersApiCallId != null &&
      this.getOrdersApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {

      this.setState({ isRefreshing: false });

      if (responseJson && !responseJson.errors && responseJson.data) {
        console.log(
          "[ShoppingCartOrdersController::receive] orderList=",
          responseJson?.data
        );
        this.setState({ orderList: responseJson?.data });
      } else {
        
        this.setState({ orderList: [] });

        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.showOrderApiCallId != null &&
      this.showOrderApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {

      this.setState({ isRefreshing: false });

      if (responseJson && !responseJson.errors && responseJson.data) {
        this.setState({
          orderItems: responseJson?.data?.attributes?.order_items?.data,
        });
      } else {
        this.setState({ orderItems: [] });

        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.createOrderItemApiCallId != null &&
      this.createOrderItemApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {

      if (responseJson && !responseJson.errors && responseJson.data) {
        this.props.navigation.navigate("ShoppingCartOrders");
      } else if (responseJson.errors) {
        this.parseApiCatchErrorResponse(responseJson.errors);
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.deleteOrderItemApiCallId != null &&
      this.deleteOrderItemApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {

      if (responseJson && !responseJson.errors && responseJson.data) {
        this.showOrder(this.state.order_id);
        this.getOrders(this.state.token);
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    this.handleApiResponse(message ,this.similerTripExperienceApiCallID,responseJson )
    this.handleApiResponse(message ,this.deleteCartItemApiCallId,responseJson )
    const apiRequestCallIds = {
    [this.apiCallIdBookMyTrip]: this.bookMyTripResponse
    }
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJsons = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

    if (apiRequestCallId != null && apiRequestCallIds[apiRequestCallId]) {
      apiRequestCallIds[apiRequestCallId](responseJsons, errorResponse);
    }

    // Customizable Area End
  };

  // Customizable Area Start
  /* istanbul ignore next */

  goToAdvancedSearchPage = async (params: string, value: string) => {
    const queryParams = {
     [params]: value
    }
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "AdvancedSearch"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
      queryParams
    });
    message.addData(
      getName(MessageEnum.NavigationRaiseMessage),
      raiseMessage
    );
    this.send(message);
  }
  
  /* istanbul ignore next */
  goToServiceProviderPageList = (type: string, params: string, value: string) => {
    if(type === "travelAgency") {
      this.props.navigation.navigate("TravelAgencyList");
    } else if(type === "travelBuddy") {
      this.props.navigation.navigate("TravelBuddyList");
    } else {
      this.goToAdvancedSearchPage(params, value)
    }
  }
  
  /* istanbul ignore next */
    handleApiResponse =(message:any, apiCallId:any, responseJson:any) => {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
        apiCallId != null &&
        apiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
  
      if (responseJson && !responseJson.errors && responseJson.data) {
        this.setState({
          similarTripExperience: responseJson.data
        });
      }
  
      if (responseJson && !responseJson.errors && responseJson.message) {
        this.setState({
          isSucess: true,
          responseStatusModal: true
        });
        setTimeout(() => {
          location.reload();
        }, 2000);
      }
    }
  }

  isNumberNull(num: number) {
    return num === null || Number.isNaN(num);
  }
  //fuction for close popup
/* istanbul ignore next */

  handleCloseModal =() => {
    this.setState(
      {
       isRemovePopupOpen:!this.state.isRemovePopupOpen
      })

  }

  hideModal = () => {
    this.setState({ isVisible: !this.state.isVisible });
  };

  navigateToAddShoppingCartOrderItem = () => {
    this.props.navigation.navigate("AddShoppingCartOrderItem");
  };

  navigateToShoppingCartOrders = () => {
    this.props.navigation.navigate("ShoppingCartOrders");
  };

  navigateToTripPage = () =>{
    this.props.navigation.navigate("LandingPage");
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };
   
   /* istanbul ignore next */
  handleDeletePopup = (elem :any)=>{
    this.setState({
      paymentFlow:false,
      isRemovePopupOpen :true,
      elem:elem
    })
  }

  setModal = (item: any) => {
    this.setState({
      id: item.post?.id,
      name: item.post?.name,
      description: item.post?.description,
      price: item.post?.price,
      currency: item.post?.currency,
      category_id: item.post?.category_id,
      created_at: item.post.created_at,
      updated_at: item.post?.updated_at,
      account_id: item.post?.account_id,
      isVisible: !this.state.isVisible,
    });
  };


  getOrders = (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const params = { filter_by: "scheduled" };

    this.getOrdersApiCallId = requestMessage.messageId;
    let urlParams = new URLSearchParams(params).toString();

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getOrdersApiEndPoint}?${urlParams}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.setState({ isRefreshing: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  showOrder = (order_id: any) => {
    this.setState({ isVisible: true });

    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.showOrderApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getOrdersApiEndPoint + "/" + `${order_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.setState({ isRefreshing: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createOrderItem = (token: string) => {
    if (
      this.isNumberNull(this.state.catalogue_id) ||
      this.isNumberNull(this.state.quantity) ||
      this.isNumberNull(this.state.taxable_value)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory,
        ""
      );
      return false;
    }

    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const order_items = {
      catalogue_id: this.state.catalogue_id,
      quantity: this.state.quantity,
      taxable: this.state.taxable,
      taxable_value: this.state.taxable_value,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createOrderItemApiCallId = requestMessage.messageId;

    const httpBody = {
      order_items,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createOrderItemApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  deleteOrderItem = (order_id: any, order_item_id: any) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteOrderItemApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteOrderItemApiEndPoint +
        "/" +
        `${order_id}` +
        "/order_items/" +
        `${order_item_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

 /* istanbul ignore next */
  handleDeleteCartItem = async(id:any) =>{
    const authToken = await getAuthToken()

    const header = {
      Authorization: `Bearer ${authToken}`,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteCartItemApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_order_management/orders/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({isDeletecart : !this.state.isDeletecart})
    
  }
 /* istanbul ignore next */
  getSimilerTripExperience = async()=>{
    const authToken = await getAuthToken()
    const header = {
      Authorization: `Bearer ${authToken}`,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.similerTripExperienceApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_productdescription/cart_similar_trips'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
 }


//function for book trip
handleBookingSummaryActionBtn = (elem: any) => {
  if (elem.attributes.trip.payment_method === "pay_later") {
    this.postOrderRequest(elem)
  } else {
    this.setState({paymentFlow:true})
 
}
}

postOrderRequest = async (elem:any) => {
  const authToken = await getAuthToken();

  const header = {
    Authorization: `Bearer ${authToken}`,
  };
  const formData = new FormData();
    formData.append("trip_id",elem.attributes.trip_schedule.trip_id);
    formData.append("quantity", elem.attributes.no_of_people);
    formData.append("trip_schedule_id", elem.attributes.trip_schedule.id);
    formData.append("type", 'booking');

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.apiCallIdBookMyTrip = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.ordersAPiEndPoint
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    formData
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.postApiMethod
  );
  
  runEngine.sendMessage(requestMessage.id, requestMessage);
   return true;
}

bookMyTripResponse = (responseJson: any, errorResponse: any) => {
  this.setState({ 
    paymentStatus: !!responseJson?.data?.data, 
    paymentResponse: responseJson 
  }, async() => {
    
      await this.handleDeleteCartItem(responseJson?.data?.data?.id - 1);

      this.gotoBookTripStatusPage();

      setTimeout(() => {
          this.navigateToTripPage()
          location.reload()
        
      }, 3000);
    
   
    
    
  });
}

gotoBookTripStatusPage = () => {
  
  const cartmessage: Message = new Message(
    getName(MessageEnum.NavigationMessage)
  );
  cartmessage.addData(
    getName(MessageEnum.NavigationTargetMessage),
    "PaymentStatus"
  );
  cartmessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  const raiseMessage: Message = new Message(
    getName(MessageEnum.NavigationPayLoadMessage)
  );
  const payload = {
    responseJson: this.state.paymentResponse,
    paymentStatus: this.state.paymentStatus
  }
  raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
    payload,
  });
  cartmessage.addData(
    getName(MessageEnum.NavigationRaiseMessage),
    raiseMessage
  );
  this.send(cartmessage);
}
  // Customizable Area End
}
