import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { CountryData } from "../../email-account-registration/src/Interface.web";
import ApiRequest from "../../../components/src/ApiRequest";
import { Categories } from "../../ProductDescription/src/Interface.web";
import { capitalizeFirstLetter, getAuthToken } from "../../../components/src/utils";
export interface TripListCard {
  id: string;
  title: string;
  description?: string;
  trip_type?: string;
  primary_image_url: string;
  city: string;
  country?: string;
  total_rating: number;
  average_rating: number;
  booking_count: number;
  account?: {
    data: {
      id: string; 
    }
  };
  order_id : any
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  arrayHolder: any;
  token: string;
  page: number;
  limit: string;
  search: string,
  tripType: string, // Store selected trip type here
  countryId: string, // Store selected country id here
  orderBy: 'title' | 'rating',
  ordering: 'asc' | 'desc',
  countryList_singleTrip: CountryData[]
  tripList: TripListCard[]
  totalPages: number;
  totalItems: number;
  openModal: boolean;
  modalMsg_singleTrip: string;
  isSuccess_singleTrip: boolean;
  deleteTripReason_singleTrip: string;
  sureDelete_singleTrip: boolean;
  deletedTripId: string;
  responseStatusModal_singleTrip: boolean;
  isSearched: boolean;
  tripTypeList: Categories[],
  loader: boolean,
  deleteReason:string
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CatalogueController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getProductApiCallId: any;
  apiCallIdCountryList_sT: string = '';
  apiCallIdShowSingleTrip: string = '';
  apiCallIdDeleteTrip: string = '';
  apiCallIdTripTypeList: string = '';
  debouncedSearch: (() => void) & { cancel: () => void };
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      arrayHolder: [],
      token: "",
      page: 1,
      limit: '12',
      search: '',
      countryId: '',
      tripType: '',
      orderBy: "title",
      ordering: "asc",
      countryList_singleTrip: [],
      tripList: [],
      totalPages: 0,
      totalItems: 0,
      openModal: false,
      modalMsg_singleTrip: '',
      isSuccess_singleTrip: false,
      deleteTripReason_singleTrip: '',
      sureDelete_singleTrip: false,
      deletedTripId: '',
      responseStatusModal_singleTrip: false,
      isSearched: false,
      tripTypeList: [],
      loader: false,
      deleteReason:''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    this.debouncedSearch = this.debounce(this.performSearch, 300);
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    super.componentDidMount();
    this.getToken();
    this.tripTypeAPIRequest();
    this.getCountryListAPIRequest_singleTrip()
    // this.getTripListAPIRequest()
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  getListRequest = (token: any) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallIds = {
      [this.apiCallIdCountryList_sT]: this.countryListApiResponse_singleTrip,
      [this.apiCallIdShowSingleTrip]: this.tripListApiResponse,
      [this.apiCallIdDeleteTrip]: this.deleteTripResponse,
      [this.apiCallIdTripTypeList]: this.getTripTypeApiResponse


      // Add more API call IDs and handlers as needed
    };


    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

    if (apiRequestCallId != null && apiRequestCallIds[apiRequestCallId]) {
      apiRequestCallIds[apiRequestCallId](responseJson, errorResponse);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  // Api Request Functions

  tripTypeAPIRequest = () => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
    };
    const requestMessage = ApiRequest({
      header: header,
      endPoint: configJSON.tripTypeListEndpoint,
      method: "GET",
    });
    this.apiCallIdTripTypeList = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCountryListAPIRequest_singleTrip = () => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
    };
    const requestMessage = ApiRequest({
      header: header,
      endPoint: configJSON.getCountryListEndpoint,
      method: "GET",
    });
    this.apiCallIdCountryList_sT = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getTripListAPIRequest = async () => {
    const { page, limit, search, tripType, countryId, orderBy, ordering } = this.state;
    const authToken = await getAuthToken();
    const header = {
      "Content-Type": configJSON.productApiContentType,
      Authorization: `Bearer ${authToken}`,
    };
    const queryParams = {
      page: page.toString(),
      limit,
      search,
      trip_type_id: tripType,
      country_id: countryId,
      order_by: orderBy,
      ordering,
    };
    const endpoint = `${configJSON.getTripListEndpoint}?${new URLSearchParams(queryParams).toString()}`;

    const requestMessage = ApiRequest({
      header,
      endPoint: endpoint,
      method: 'GET',
    });
    this.apiCallIdShowSingleTrip = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({loader: true})
  }

  deleteTripAPIRequest = async () => {
    const authToken = await getAuthToken();
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      Authorization: `Bearer ${authToken}`,
    };
    let queryParams: Record<string, string | boolean> = {};

const addQueryParamIfNotEmptyValue = (paramName: string, paramValue: string | boolean | undefined) => {
  if (paramValue !== undefined && paramValue !== '') {
    queryParams[paramName] = paramValue;
  }
};

addQueryParamIfNotEmptyValue('confirm_deletion', true);
addQueryParamIfNotEmptyValue('cancellation_reason', this.state.deleteReason);

const stringQueryParams: Record<string, string> = Object.keys(queryParams).reduce((acc, key) => {
  acc[key] = String(queryParams[key]);
  return acc;
}, {} as Record<string, string>);

const queryString = new URLSearchParams(stringQueryParams).toString();
const endpoint = `${configJSON.getTripListEndpoint}/${this.state.deletedTripId}?${queryString}`;
    const requestMessage = ApiRequest({
      header: header,
      endPoint: `${endpoint}`,
      method: "DELETE",
    });
   this.apiCallIdDeleteTrip = requestMessage.messageId;
   runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  // Api Response Functions

  getTripTypeApiResponse = (responseJson: any, errorReponse: any) => {
    const tripTypeData = responseJson.data;
    if (tripTypeData) {
      const tripTypeList: Categories[] = tripTypeData.map(
        (tripType: any) => {
          const value = tripType.id;
          const label = capitalizeFirstLetter(tripType.attributes.name);
          return {label, value};
        }
      );

      this.setState({
        tripTypeList: tripTypeList,
      }, () => this.getTripListAPIRequest());
    }
  }

  countryListApiResponse_singleTrip = (responseJson: any, errorReponse: any) => {
    const countryDataArray = responseJson.country_data.data;
    if (countryDataArray) {
      const countryList_singleTrip: CountryData[] = countryDataArray.map(
        (country: any) => {
          const attributes = country.attributes;
          attributes.country_code = '+' + attributes.country_code; // Add '+' sign
          attributes.id = country.id;
          return attributes;
        }
      );

      this.setState({
        countryList_singleTrip: countryList_singleTrip,
      });
    }
  }

  tripListApiResponse = (responseJson: any, errorReponse: any) => {
    const tripDataArray = responseJson.data;
    if (tripDataArray) {
      const totalPages = responseJson?.page_options?.total_pages;
      const current_page = responseJson?.page_options?.current_page;
      const per_page = responseJson?.page_options?.per_page;
      const total_items = responseJson?.page_options?.total_items;

      const tripList: TripListCard[] = tripDataArray.map(
        (trip: any) => {
          const attributes = trip.attributes;
          attributes.id = trip.id;
          return attributes;
        }
      );

      this.setState({
        loader: false,
        tripList: tripList,
        totalPages,
        page: current_page,
        limit: per_page,
        totalItems: total_items
      });
    }
    this.setState({loader: false})
  }

  handleDeletereason =(value:string) =>{
    this.setState({deleteReason:value})
  }

  deleteTripResponse = (responseJson: any, errorReponse: any) => {
    if (responseJson.status === 500) {
      this.setState({
        responseStatusModal_singleTrip: true,
        isSuccess_singleTrip: false,
        modalMsg_singleTrip: 'Sorry, something went wrong!'
      })
      return;
    }

    if (responseJson.messages && !responseJson.errors) {
      const msg = responseJson.messages[0];
      const dynamicKey = Object.keys(msg)[0];
      const messageValue = msg[dynamicKey] || "Trip has been closed successfully.";
      this.setState({
        openModal: false,
        responseStatusModal_singleTrip: true,
        isSuccess_singleTrip: true,
        modalMsg_singleTrip: messageValue,
      },  () => {
        setTimeout(() => {
          this.getTripListAPIRequest();
        }, 700); 
      });
    } else {
      this.setState({
        openModal: false,
        responseStatusModal_singleTrip: true,
        isSuccess_singleTrip: false,
        modalMsg_singleTrip: 'Sorry, something went wrong! ',
      })
    }
  }


  // Internal Functions

  navigateToCreateTrip = () => {
    this.props.navigation.navigate("CreateTrip")
  }

  navigateToCalendarView = () => {
    this.props.navigation.navigate("TripListCalendarView")
  }

  // Handle other filter changes (e.g., trip type, country)
  handleFilterChange = (filterName: string, value: string) => {
    const newState: Partial<S> = {}; // Define an empty partial state object
  
    const sortMappings: Record<string, { orderBy: "title" | "rating", ordering: 'asc' | 'desc' }> = {
      'AtoZ': { orderBy: 'title', ordering: 'asc' },
      'ZtoA': { orderBy: 'title', ordering: 'desc' },
      'lowToHigh': { orderBy: 'rating', ordering: 'asc' },
      'highToLow': { orderBy: 'rating', ordering: 'desc' },
      'Any': { orderBy: 'title', ordering: 'asc' },
    };
  
    if (filterName === 'sortBy' && sortMappings[value]) {
      const { orderBy, ordering } = sortMappings[value];
      newState.orderBy = orderBy;
      newState.ordering = ordering;
    } else if ((filterName === 'countryId' || filterName === 'tripType') && value !== 'Any') {
      newState[filterName] = value;
    } else if ((filterName === 'countryId' || filterName === 'tripType') && value === 'Any') {
      newState[filterName] = '';
    }
  
  
    this.setState(newState as Pick<S, keyof S>, () => {
      this.getTripListAPIRequest();
    });
  };
  
  
  

  // Function to perform the actual search
  performSearch = () => {
    // Call the API with the updated search query
    
    this.getTripListAPIRequest();
  };

  debounce<T extends (...args: any[]) => void>(func: T, delay: number): T & { cancel: () => void } {
    let timeout: any;

    const debounced = (...args: any[]) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), delay);
    };


    return debounced as T & { cancel: () => void };
  }


  // Handle input change
  handleSearchChange = (value: string) => {
    this.setState({ isSearched: value.length > 0, search: value });

    // Call the debounced function
    this.debouncedSearch();
  };


  openModal = (id: string) => {
    this.setState({
      deletedTripId: id,
      openModal: true,
      deleteTripReason_singleTrip: ''
    })
  }

  onCloseModal = () => {
    this.setState({
      openModal: false,
      responseStatusModal_singleTrip: false,
    })
  }

  handleProceedwithDeleteTripReason = () => {
    this.setState({
      sureDelete_singleTrip: true,

    })
  }

  handleDeleteTrip = (id: string, reason: string) => {
    this.setState({
      deletedTripId: id,
      deleteTripReason_singleTrip: reason
    })
  }

  handlePaginationChange = (e: any, value: number) => {
    const pageNumber = Number(value);
    this.setState({
      page: pageNumber,
    },() =>  this.getTripListAPIRequest())
  }
  // Customizable Area End
}
